import React, { FC } from 'react';
import IndexPage from '../../../templates/pages/IndexPage';
import AssessmentTable from '../tables/AssessmentTable';


const AssessmentIndex: FC = (): JSX.Element => <IndexPage
    title={ 'Risicoborgingen' }
    Table={ AssessmentTable }
    preventRedirect={true}
/>;
export default AssessmentIndex;