import React, { FC } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import { optionIsSelected } from '../../lib/samfe/components/Form/Support/FieldSupport';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import { ucFirst } from '../../lib/samfe/modules/Parse/String';
import ProductTypeForm from '../productType/ProductTypeForm';
import useProductType from '../productType/useProductType';
import { AttributeDto, AttributeModel, AttributeRelationsBluePrint, AttributeType, attributeTypes } from './AttributeTypes';
import useAttribute from './useAttribute';


const AttributeForm: FC<ExtendFormModalProps<AttributeDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    const attribute = useAttribute();

    /**
     *
     */
    const shape = (
        currentAttribute: AttributeModel|undefined = undefined
    ): Shape<AttributeDto> => ({
        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text'),


        product_type_id: Yup.number()
            .required()
            .label('Producttype')
            .hidden(id !== undefined)
            .controlType('selectSearch')
            .selectSearchConfig({
                useHook: useProductType,
                expectsInitialModel: false,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    displayName: model => `${ model.name }`,
                    limit: 'all',
                    filter: 'archived=0',
                    FormModal: ProductTypeForm
                }
            }),

        type: Yup.string()
            .required()
            .label('Invoertype')
            .hidden(currentAttribute?.type !== undefined
                    ? !([ 'textarea', 'text', 'number'] as AttributeType[]).includes(currentAttribute.type)
                    : false
            )
            .controlType('select')
            .options(attributeTypes
                .filter(at => currentAttribute?.type !== undefined ?([ 'textarea', 'text', 'number'] as AttributeType[]).includes(at): true )
                .map((type, i) => ({
                displayName: ucFirst(type),
                value: type,
                selected: optionIsSelected(currentAttribute?.type, type, i)
            }))),

        order: Yup.number()
            .hidden(true)
            .label('Positie')
            .controlType('input')
            .inputType('number'),

        description: Yup.string()
            .label('Omschrijving')
            .controlType('textArea')
            .inputType('text')

    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<AttributeDto>(shape());

    const initializer = async() => {
        const currentAttribute = await attribute.getItem(id, { with: [ 'productType' ] });
        setShape(shape(currentAttribute));
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<AttributeModel, AttributeDto, AttributeRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useAttribute,
        onSuccess: onSuccess
    });


    return <FormModal
        id={ id }
        resource={ 'Producteigenschap' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default AttributeForm;
