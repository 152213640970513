import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import useToaster from '../../lib/samfe/components/Toaster/useToaster';
import { datetimeToLocale } from '../../lib/samfe/modules/Parse/Date';
import { SortObjectArrayByDate } from '../../lib/samfe/modules/Parse/Object';
import CustomerForm from './CustomerForm';
import { CustomerModel, CustomerRelationsBluePrint } from './CustomerTypes';
import useCustomer from './useCustomer';


type Props = Required<ExtendTableProps>

const CustomerTable: FC<Props> = () => {

    const httpHook = useCustomer();
    const { setToasterProps } = useToaster();

    const rows = useMemo((): TableColumns<CustomerModel, CustomerRelationsBluePrint>[] => [
        {
            header: {
                children: 'Klant',
                sortCol: 'number'
            },
            column: (item) => ({
                children: item.number,
                linkTo: (item) => `${ item.id }`
            }),
            type: 'id',
            style: {
                width: '2rem'
            }
        },
        {
            header: {
                children: 'WC nummer',
                sortCol: 'wc_id'
            },
            column: (item) => ({
                children: item.wc_id
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Bedrijfsnaam',
                sortCol: 'company'
            },
            column: (item) => ({
                children: item.company
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Contact (Telefoon)',
                sortCol: 'phone'
            },
            column: (item) => ({
                children: item.phone
            }),
            type: 'tel'
        },
        {
            header: {
                children: 'Contact (E-mail)',
                sortCol: 'email'
            },
            column: (item) => ({
                children: item.email
            }),
            type: 'mail'
        },
        {
            header: {
                children: 'Klantnotitie'
            },
            column: (item) => ({
                children: `${ item.comments }`
            }),
            type: 'text'
        }
    ], []);

    return (
        <Table
            id={ 'customers' }
            rows={ rows }
            http={ {
                hook: httpHook,
                sortCol: 'company',
                sortDir: 'ASC',
                searchCols: [ 'first_name', 'last_name', 'company', 'number', 'email', 'phone' ]
            } }
            forms={ {
                edit: {
                    Form: CustomerForm,
                    id: (record) => record.id
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => record.company,
                    resourceName: () => 'Klant'
                }
            } }
            callbacks={ [
                {
                    title: 'API sleutel kopiëren',
                    onClick: (item) => {
                        navigator.clipboard.writeText(item.api_key).then(() => {
                            setToasterProps({
                                title: 'API key gekopieerd',
                                show: true
                            });
                        });

                    }
                }
            ] }
        />
    );
};

export default CustomerTable;