import React, { FC } from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { filterIds } from '../../../../lib/samfe/modules/Parse/Url';
import { modelNotArchived } from '../../../../lib/samfe/types/ModelTypes';
import useProducer from '../../../producer/useProducer';
import useArticle from '../../useArticle';
import { ArticleProducerDto, ArticleProducerModel, ArticleProducerRelationsBluePrint } from './ArticleProducerTypes';
import useArticleProducer from './useArticleProducer';


/**
 * @var {number|undefined} id = producer id
 * @var {number|undefined} parentId = article id
 *
 */
const ArticleProducerForm: FC<ExtendFormModalProps<ArticleProducerDto>> = ({
    id,
    open,
    setOpen,
    onSuccess,
    parentId
}): JSX.Element => {

    const article = useArticle();

    /**
     *
     */
    const shape = (occupiedProducerIds: number[] = []): Shape<ArticleProducerDto> => ({
        article_id: Yup.number()
            .hidden(true)
            .label('Categorie')
            .controlType('input')
            .inputType('text'),

        producer_id: Yup.number()
            .required()
            .label('Leverancier')
            .hidden(id !== undefined)
            .controlType('selectSearch')
            .selectSearchConfig({
                expectsInitialModel: false,
                useHook: useProducer,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    limit: 'all',
                    filter: filterIds({ ids: occupiedProducerIds, exclude: true }) ?? 'archived=0,active=1',
                    displayName: model => `${ model.name }`
                }
            }),

        custom_name: Yup.string()
            .label('Artikelnaam bekend bij leverancier')
            .controlType('input')
            .inputType('text')
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<ArticleProducerDto>(shape());


    const initializer = async() => {
        const occupiedProducerIds: number[] = await article.getItem(parentId, { with: [ 'articleProducers' ] })
            .then(article => (article?.articleProducers ?? [])
                .filter(ap => modelNotArchived(ap.archived))
                .map(ap => ap.producer_id!)
            );
        setShape(shape(occupiedProducerIds));
    };


    /**
     *
     */
    const { formikConfig, formFields } = useForm<ArticleProducerModel, ArticleProducerDto, ArticleProducerRelationsBluePrint>({
        id,
        parentId,
        parentKey: 'article_id',
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useArticleProducer,
        onSuccess: onSuccess
    });

    return <FormModal
        id={ id }
        parentId={ parentId }
        resource={ 'Artikel van Leverancier' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default ArticleProducerForm;
