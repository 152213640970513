import React, { FC, useMemo } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { API_URL } from '../../../config/config';
import useFileHandler from '../../document/useFileHandler';
import { SaleFileModel, SaleFileRelationsBluePrint, useProductSaleFile } from './SaleDocTypes';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ProductDossierTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useProductSaleFile(parentId);

    const { downloadFileByUrl } = useFileHandler();


    const rows = useMemo((): TableColumns<SaleFileModel, SaleFileRelationsBluePrint>[] => [
        {
            header: {
                children: 'Nummer',
                sortCol: 'productName'
            },
            column: (item) => ({
                children: item.productNumber
            }),
            style: {
              width: '8rem'
          },
            type: 'text',
        },
        {
          header: {
              children: 'Product',
              sortCol: 'productName'
          },
          column: (item) => ({
              children: item.productName
          }),
          type: 'text',
      }
    ], []);

    const getFileUrl = (item: SaleFileModel, action: 'open'|'download') => (
        `${ API_URL }${ item[action] }`
    );

    return <>
        <Table
            id={ 'product-dossiers' }
            rows={ rows }
            http={ {
                hook: httpHook,
                sortCol: 'productName',
                sortDir: 'ASC',
                showAll: true
            } }
            callbacks={ [
                {
                    title: 'Openen',
                    onClick: (item) => {
                        window.open(getFileUrl(item, 'open'), '_blank');
                    },
                    disableForRow: (item) => !item.productId
                },
                {
                    title: 'Downloaden',
                    onClick: (item) => {
                        const name = `productdossier-${item.productNumber}-v${item.productVersion}`;
                        downloadFileByUrl(getFileUrl(item, 'download'), name, 'pdf');
                    },
                    disableForRow: (item) => !item.productId
                }
            ] }
            styling={ {
                variation: 'buttons'
            } }        />
    </>;
};

export default ProductDossierTable;