import React, {useEffect, useState} from "react";
import {PurchaseDto, PurchaseModel} from "../../PurchaseTypes";
import {ExtendFormModalProps} from "../../../../lib/samfe/components/Modal/FormModal/FormModal";
import usePurchase from "../../usePurchase";
import SendPurchaseInnerForm from "./SendPurchaseInnerForm";
import Modal from "../../../../lib/samfe/components/Modal";
import useToaster from "../../../../lib/samfe/components/Toaster/useToaster";

/**
 * @fixme
 * @param id
 * @param open
 * @param setOpen
 * @constructor
 */
const SendPurchaseForm: React.FC<ExtendFormModalProps<PurchaseDto>> = ({parentId, open, setOpen, onSuccess}): JSX.Element => {

    const purchase = usePurchase()
    const [currentPurchase, setCurrentPurchase] = useState<PurchaseModel | undefined>(undefined)
    const {setToasterProps} = useToaster();

    useEffect(() => {
        purchase.getItem(parentId, {
            with: ['producer', 'purchaseRows.purchase.producer', 'purchaseRows', 'purchaseRows.purchase']
        }).then(setCurrentPurchase);
    }, [])

    const onSuccessInternal = () => {
        setToasterProps({
            show: true,
            type: 'success',
            title: 'Order verstuurd naar leverancier'
        });
        setOpen(false);
        onSuccess && onSuccess(currentPurchase?.id);
    }

    return <Modal open={open} setOpen={setOpen} title={'Order verzenden'} footer={<></>}>
        {
            currentPurchase != undefined
                ? <SendPurchaseInnerForm  purchase={currentPurchase} onSuccess={onSuccessInternal}/>
                : <>Geen order data beschikbaar.</>
        }
    </Modal>

}
export default SendPurchaseForm;