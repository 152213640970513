import React, { FC } from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import { RiskCodeDto, RiskCodeModel, RiskCodeRelationsBluePrint } from './RiskCodeTypes';
import useRiskCode from './useRiskCode';


const RiskCodeForm: FC<ExtendFormModalProps<RiskCodeDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    /**
     *
     */
    const shape = (): Shape<RiskCodeDto> => ({
        name: Yup.string()
            .required()
            .label('Naam')
            .controlType('input')
            .inputType('text')

    });


    /**
     *
     */
    const { validationSchema, setShape } = useSchema<RiskCodeDto>(shape());

    const initializer = async() => {
        setShape(shape());
    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<RiskCodeModel, RiskCodeDto, RiskCodeRelationsBluePrint>({
        id,
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useRiskCode,
        onSuccess: (modelId) => {
            window.location.reload();
            // @fixme no show page active
            //onSuccess?.(modelId);
        }
    });


    return <FormModal
        id={ id }
        resource={ 'Risicocode' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default RiskCodeForm;
