import React, { FC, useCallback, useMemo, useState } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { dispatchHttpEvent } from '../../../events/Http.event';
import WarningModal from '../../../lib/samfe/components/Modal/WarningModal';
import { batchCodeLabel, expirationDateLabel } from '../../charge/ChargeFunctions';
import { ChargeModel, ChargeRelationsBluePrint } from '../../charge/ChargeTypes';
import useCharge from '../../charge/useCharge';
import useAssessment from '../http/useAssessment';
import ChargeOriginColumn from './components/ChargeOriginColumn';
import ChargeProductColumn from './components/ChargeProductColumn';


type Props = Required<ExtendTableProps>

const BlockedAssessmentTable: FC<Props> = () => {


    const httpHook = useCharge();
    const unblockChargeHook = useCharge();
    const assessmentHook = useAssessment();

    const [ currentCharge, setCurrentCharge ] = useState<ChargeModel>();
    const [ openAnalyseModal, setOpenAnalyseModal ] = useState(false);
    const [ openUnBlockModal, setOpenUnBlockModal ] = useState(false);


    const rows = useMemo((): TableColumns<ChargeModel, ChargeRelationsBluePrint>[] => [
        {
            header: {
                children: 'Product'
            },
            column: (item) => ({
                children: <>
                    <ChargeProductColumn
                        hideProduct={ false }
                        productId={ item.product_id! }
                        productName={ item.product!.name! }
                        articleNumber={ item.article!.number! }
                    />
                    <ChargeOriginColumn
                        producerName={ item.purchaseRow?.purchase?.producer?.name }
                        purchaseId={ item.purchaseRow?.purchase_id }
                        repackId={ undefined }
                        parentCharge={ undefined }
                    />
                </>
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Charge',
            },
            column: (item) => ({
                children: batchCodeLabel(item),
                linkTo: (item) => `/charges/${ item.id }?current-tab=risk-assessments`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Inboekdatum',
            },
            column: (item) => ({
                children: item.received_date
            }),
            type: 'date'
        },
        {
            header: {
                children: 'THT',
            },
            column: (item) => ({
                children: expirationDateLabel(item.expiration_date)
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Risico',
            },
            column: (item) => ({
                children: <ul>
                    { (item.assessments ?? []).map(ass => <li key={ ass.id }>
                        { ass.productRisk?.risk?.name }
                    </li>) }
                </ul>
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Analysefrequentie',
            },
            column: (item) => ({
                children: <ul>
                    { (item.assessments ?? []).map(ass => <li key={ ass.id }>
                        { (ass.frequency ?? 0)>0 ?`${ ass.frequencyIndex }/${ ass.frequency }` :'nvt' }
                    </li>) }
                </ul>
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Blokkeernotitie',
            },
            column: (item) => ({
                children: <ul>
                    { (item.assessments ?? []).map(ass => <li key={ ass.id }>
                        { ass.productRisk?.blocked_comment ?? '-' }
                    </li>) }
                </ul>
            }),
            type: 'element'
        }
    ], []);

    const unblockCharge = async(charge?: ChargeModel) => {
        if (!charge) {
            return;
        }
        await unblockChargeHook
            .update(charge.id, { status: 'processed' })
            .then(() => setCurrentCharge(undefined))
            .finally(dispatchHttpEvent);
    };

    const processUsableCharges = async(charges: ChargeModel[]) => {
        const usableCharges: ChargeModel[] = [];
        for (const charge of charges) {
            if (charge.assessments?.filter(ass => ass.assessment_status == 'not_applicable').length == 0) {
                await unblockCharge(charge);
                continue;
            }
            usableCharges.push(charge);
        }
        return usableCharges;
    };

    const postProcessData = useCallback(async (charges: ChargeModel[]) => {
        const items: ChargeModel[] = [];
        await processUsableCharges(charges).then(processedCharges => {
            processedCharges.forEach(charge => {
                const baseCharge: ChargeModel = { ...charge };
                delete baseCharge.assessments;
                (charge.assessments ?? [])
                    .filter(ass => ass.blocked == true && ass.assessment_status == 'not_applicable')
                    .forEach(ass => {
                    items.push({ ...baseCharge, assessments: [ ass ] });
                });
            });
        });
        return items;
    }, []);


    const checkIfChargeCanBeUnblocked = async() => {
        if (!currentCharge?.id) {
            return false;
        }

        return await assessmentHook
            .getList({
                select: [ 'id', 'charge_id', 'blocked' ],
                filter: `charge_id=${ currentCharge.id },archived=false`
            })
            .then(assessments => {
                // Check if no assessment is blocked, if none blocked == true, proceed unblocking.
                return assessments.find(ass => ass.blocked == true) == undefined;
            });
    };

    const handleUnblockCharge = async() => {
        const chargeCanBeUnBlocked = await checkIfChargeCanBeUnblocked();
        if (!currentCharge || !chargeCanBeUnBlocked) {
            setCurrentCharge(undefined);
            dispatchHttpEvent();
            return;
        }
        await unblockCharge(currentCharge);
    };


    const handleMarkForAnalysis = async() => {
        if (!currentCharge) {
            return;
        }
        const applicableAssessments = (currentCharge.assessments ?? [])
            .filter(ass => ass.blocked == true && currentCharge.status == 'blocked');

        for (const ass of applicableAssessments) {
            await assessmentHook.update(ass.id, {
                assessment_status: 'to_test',
                should_be_assessed: true
            });
        }
        await handleUnblockCharge();
    };


    const handleUnblockAssessment = async() => {
        if (!currentCharge) {
            return;
        }
        const applicableAssessments = (currentCharge.assessments ?? [])
            .filter(ass => ass.blocked == true && currentCharge.status == 'blocked');

        for (const ass of applicableAssessments) {
            await assessmentHook.update(ass.id, { blocked: false });
        }
        await handleUnblockCharge();
    };

    return <>
        <Table
            id={ 'blocked-assessments' }
            rows={ rows }
            postProcessData={ postProcessData }
            http={ {
                hook: httpHook,
                with: [
                    'assessments',
                    'assessments.productRisk.risk',
                    'assessments.frequencyIndex',
                    'product',
                    'article',
                    'purchaseRow.purchase.producer'
                ],
                sortCol: 'id',
                sortDir: 'ASC',
                filter: {
                    column: 'status',
                    operator: '=',
                    value: 'blocked,assessments.blocked=true'
                },
                filterConfig: {
                    hideAllFilters: true
                }
            } }
            styling={ {
                variation: 'buttons'
            } }
            callbacks={ [
                {
                    title: 'Verplaatsen naar Risicoborgingen',
                    onClick: (item) => {
                        setCurrentCharge(item);
                        setOpenAnalyseModal(true);
                    }
                },
                {
                    title: 'Blokkering opheffen',
                    onClick: (item) => {
                        setCurrentCharge(item);
                        setOpenUnBlockModal(true);
                    }
                }
            ] }
        />

        { currentCharge != undefined && openAnalyseModal && <WarningModal
            title={ 'Markeren voor analyse' }
            text={ 'Weet u zeker dat u deze charge wilt analyseren?' }
            setOpen={ setOpenAnalyseModal }
            open={ openAnalyseModal }
            confirmCallback={ handleMarkForAnalysis }
        /> }

        { currentCharge != undefined && openUnBlockModal && <WarningModal
            title={ 'Blokkering opheffen' }

            text={ <>
                <p>Weet u zeker dat u de blokkering <strong>voor deze charge</strong> wilt opheffen?</p>
                <p>De volgende charge wordt alsnog geblokkeerd.</p>
                <p> Dit kan uitgezet worden bij Product { '->' } Risico&apos;s</p>
            </> }

            setOpen={ setOpenUnBlockModal }
            open={ openUnBlockModal }
            confirmCallback={ handleUnblockAssessment }
        /> }
    </>;
};

export default BlockedAssessmentTable;
