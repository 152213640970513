import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { ChargeLocationModel, ChargeLocationRelationsBluePrint } from '../../../location/ChargeLocationTypes';
import { useChargeLocation } from '../../../location/useLocation';
import ChargeLocationMoveForm from './ChargeLocationMoveForm';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ChargeLocationTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useChargeLocation();

    const rows = useMemo((): TableColumns<ChargeLocationModel, ChargeLocationRelationsBluePrint>[] => [
        {
            header: {
                children: 'Pand'
            },
            column: (item) => {
                if (!item.location?.group) {
                    return {
                        children: <span className={ 'text-fire font-medium' }>Oude locatie</span>
                    };
                }

                if (!item.location.group?.key) {
                    return {
                        children: <>
                            <p>{ item.location.group.name }</p>
                            <small>Geen korte naam aanwezig</small>
                        </>
                    };
                }

                return {
                    children: item.location.group.key
                };
            },
            type: 'text'
        },
        {
            header: {
                children: 'Stelling'
            },
            column: (item) => ({
                children: item.location?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Hoogte'
            },
            column: (item) => ({
                children: item.level ?? '-'
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Datum'
            },
            column: (item) => ({
                children: item.updated_at
            }),
            type: 'date'
        }
    ], []);

    return <>
        <Table
            id={ 'locations' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/locations/${ item.location_id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: [ 'location.name', 'location.group.key', 'location.group.name' ],
                with: [ 'location.group' ],
                sortCol: 'updated_at',
                sortDir: 'DESC',
                filter: {
                    column: 'charge_id',
                    operator: '=',
                    value: parentId,
                },
                filterConfig: {
                  //hideArchived: true
                },
            } }
            forms={ {
                edit: {
                    Form: ChargeLocationMoveForm,
                    parentId: (record) => parentId,
                    id: (record) => record.id,
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.location?.name }`,
                    resourceName: () => 'Charge locatie'
                }
            } }
        />
    </>;
};

export default ChargeLocationTable;