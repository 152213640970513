import React, { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dispatchHttpEvent } from '../../../../events/Http.event';
import { SaveButton } from '../../../../lib/samfe/components/Button';
import TextArea from '../../../../lib/samfe/components/Form/Generic/TextArea';
import ExampleGraph from '../../../../lib/samfe/components/Graph/ExampleGraph';
import Stats from '../../../../lib/samfe/components/Stats';
import { ucFirst } from '../../../../lib/samfe/modules/Parse/String';
import useId from '../../../../lib/samfe/modules/Router/useId';
import ShowPage from '../../../../templates/pages/ShowPage';
import { getElementUri } from '../../ElementFunctions';
import { ElementModel } from '../../ElementTypes';
import useCustomElementActions from '../../useCustomElementActions';
import useElement from '../../useElement';
import ElementProductTable from '../elementProduct/ElementProductTable';
import ElementRiskTable from '../elementRisk/ElementRiskTable';
import ElementRiskAssuranceTable from '../elementRiskAssurance/ElementRiskAssuranceTable';
import ElementRiskRegulationTable from '../elementRiskRegulation/ElementRiskRegulationTable';
import ElementRiskTypeTable from '../elementRiskType/ElementRiskTypeTable';
import ElementExcipientForm from './ElementExcipientForm';


const ElementExcipientShow: FC = (): JSX.Element => {

    const id = useId();
    const element = useElement();
    const navigate = useNavigate();

    const [ currentElement, setCurrentElement ] = useState<ElementModel>();
    const [ comments, setComments ] = useState(currentElement?.comments);
    const customButtonActions = useCustomElementActions(currentElement);

    useEffect(() => {
        if (!currentElement) {
            return;
        }
        if (currentElement?.type === 'verbinding') {
            navigate(getElementUri(currentElement), { replace: true });
            return;
        }
        setComments(currentElement.comments);
    }, [ currentElement ]);

    const title = useMemo(() => {
        const elementName = `${ currentElement?.name }`;
        if (currentElement?.excipient_id) {
            return `${ elementName } (${ currentElement.excipient?.name })`;
        }
    }, [ currentElement?.name, currentElement?.excipient_id, currentElement?.excipient?.name ]);

    const titleWarningMessage = useMemo(() => {
        if (!currentElement) {
            return undefined
        }
        if (!currentElement?.is_reviewed) {
            return 'Niet geëvalueerd';
        }
        return undefined;
    }, [ currentElement ]);

    return (
        <ShowPage
            customButtonActions={ customButtonActions }
            title={ title }
            titleWarningMessage={ titleWarningMessage }
            breadcrumb={ title }
            setCurrentModel={ setCurrentElement }
            FormModal={ ElementExcipientForm }
            httpHook={ useElement }
            relations={ [
                'excipient',
                'files'
            ] }
            upload={ {
                modelName: 'ElementModel',
                files: currentElement?.files,
                fileTypes: [ 'Grondstof certificaat', 'Overig' ]
            } }
            tabs={ currentElement ?[
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <Stats stats={ [
                            {
                                name: 'Type',
                                value: ucFirst(currentElement?.type ?? '')
                            },
                            {
                                name: 'Functie',
                                value: ucFirst(currentElement?.excipient?.name ?? '')
                            },
                            {
                                name: 'Laatst beoordeeld',
                                value: currentElement.reviewed_at ?? '-'
                            }
                        ] }/>
                        <div className={ 'mt-4 mb-16' }>
                            <TextArea
                                label={ 'Notities' }
                                name={ 'comments' }
                                value={ comments }
                                onChange={ v => setComments(`${ v ?? '' }`) }
                            />
                            <SaveButton className={ 'float-right' } onClick={ () => {
                                element.update(id, {
                                    comments: comments
                                }).then(dispatchHttpEvent);
                            } }/>

                        </div>
                        <ExampleGraph/>
                    </>
                },
                {
                    name: 'Producten',
                    id: 'products',
                    element: <ElementProductTable parentId={ id }/>
                },
                {
                    name: 'Risico\'s',
                    id: 'risks',
                    element: <ElementRiskTable parentId={ id }/>
                },
                {
                    name: 'Risicotypes',
                    id: 'riskTypes',
                    element: <ElementRiskTypeTable parentId={ id }/>
                },
                {
                    name: 'Risicoregulaties',
                    id: 'riskRegulations',
                    element: <ElementRiskRegulationTable parentId={ id }/>
                },
                {
                    name: 'Risicoborging',
                    id: 'riskAssurances',
                    element: <ElementRiskAssuranceTable parentId={ id }/>
                }
            ] :[] }
        />
    );
};

export default ElementExcipientShow;