import { useMemo } from 'react';
import { useTableContext } from '../providers/Table.provider';
import { TableColumns } from '../types/TableRow';
import { ThComponentProps } from '../types/TableTh';
import useTableCallbacks from './useTableCallbacks';
import useTableDeArchive from './useTableDearchive';
import useTableForms from './useTableForms';


const useTableHeaders = <T extends object, K extends keyof T, R extends string, V = T[K]>(colData: TableColumns<T, R>[]): ThComponentProps<T, R>[] => {

    const { hasTableForms, archiveFormConfig } = useTableForms<T, K, R, V>();
    const {hasArchivedResources} = useTableDeArchive()
    const { hasTableCallbacks } = useTableCallbacks<T, R>();
    const { rowSort } = useTableContext<T, R>();

    const hasActions = useMemo(() => {
        return hasTableForms || hasArchivedResources || hasTableCallbacks || archiveFormConfig != undefined;
    }, [ hasTableForms, hasTableCallbacks, archiveFormConfig, hasArchivedResources ]);

    const hasDnd = useMemo(() => {
        return rowSort?.orderColumn != undefined
    }, [ rowSort?.orderColumn ]);

    return useMemo((): ThComponentProps<T, R>[] => {
        const items = colData.map(item => ({
            ...item.header,
            type: item.type ?? 'text',
        }));

        if (hasActions) {
            items.push({
                children: 'Actie(s)',
                className: 'text-right',
                //@ts-ignore
                type: 'action'
            })
        }

        if (hasDnd) {
            items.unshift({
                children: 'Positie',
                type: 'element',
                className: 'text-left'
            })
        }
        return items;
    }, [ colData, hasActions, hasDnd ]);
};

export default useTableHeaders;
