import React, { FC, ReactNode, useMemo } from 'react';
import { datetimeToLocale } from '../../../../lib/samfe/modules/Parse/Date';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import useFileHandler from '../../../document/useFileHandler';
import AssessmentStatusPill from '../../components/AssessmentStatusPill';
import useAssessmentIndex from '../../hooks/useAssessmentIndex';
import useAssessmentIsApproved from '../../hooks/useAssessmentIsApproved';
import useAssessmentStatus from '../../hooks/useAssessmentStatus';
import { AssessmentHistory, GetAssessmentStatusName } from '../../types/AssessmentTypes';
import useAssessmentFiles from '../hooks/useAssessmentFiles';
import Icon from "../../../../lib/samfe/components/Icon/Icon";


export type WithAssessmentTimeStamps = {
    withRequestedAt?: boolean
    witApprovedAt?: boolean
}

export type AssessmentTimeStamps = {
    requestedAt?: ReactNode,
    approvedAt?: ReactNode,
}

type AssessmentRiskItemStructureProps = AssessmentTimeStamps&{
    coverage: ReactNode,
    risk: ReactNode,
    status: ReactNode,
    frequency: ReactNode,

}
const AssessmentRiskItemStructure: FC<AssessmentRiskItemStructureProps> = ({
    coverage,
    risk,
    status,
    frequency,
    requestedAt,
    approvedAt
}) => {

    const width = useMemo(() => {
        const baseWidth = 38;
        const dateWidth = 12;
        const requestedAtWidth = requestedAt ?dateWidth :0;
        const approvedAtWidth = approvedAt ?dateWidth :0;
        return baseWidth + requestedAtWidth + approvedAtWidth;
    }, [ requestedAt, approvedAt ]);

    const nCols = useMemo(() => {
        const baseCols = 9;
        const dateColSpan = 1;
        const requestedAtCols = requestedAt ?dateColSpan :0;
        const approvedAtCols = approvedAt ?dateColSpan :0;
        return baseCols + requestedAtCols + approvedAtCols;
    }, [ requestedAt, approvedAt ]);

    return (
        <div className={ classNames('grid gap-x-2',
            nCols == 9 && 'grid-cols-9',
            nCols == 10 && 'grid-cols-10',
            nCols == 11 && 'grid-cols-11'
        ) }
             style={ {
                 minWidth: `${ width }rem`,
                 maxWidth: `${width+10}rem`,
                 width: `calc(100vw - 72rem)`
             } }
        >
            <div className={ 'col-span-2 !text-left' }>
                { coverage }
            </div>
            <div className={ 'col-span-4 !text-left' }>
                { risk }
            </div>
            <div className={ 'col-span-2 !text-left' }>
                { status }
            </div>
            <div className={ 'col-span-1 !text-right' }>
                { frequency }
            </div>
            { requestedAt && <div className={ 'col-span-1 !text-right' }>
                { requestedAt }
            </div> }

            { approvedAt && <div className={ 'col-span-1 !text-right' }>
                { approvedAt }
            </div> }
        </div>
    );
};

type AssessmentRiskItemTitleProps = WithAssessmentTimeStamps
export const AssessmentRiskItemTitle: FC<AssessmentRiskItemTitleProps> = ({ withRequestedAt, witApprovedAt }) => {
    return (
        <AssessmentRiskItemStructure
            coverage={ <small>Status</small> }
            risk={ <small>Risico</small> }
            status={ <small>Actie</small> }
            frequency={ <small>Analysefrequentie</small> }
            requestedAt={ withRequestedAt ?<small>Aanvraagdatum</small> :undefined }
            approvedAt={ witApprovedAt ?<small>Borgingsdatum</small> :undefined }
        />
    );
};


type Props = WithAssessmentTimeStamps&{
    item: AssessmentHistory,
}

const AssessmentRiskItem: FC<Props> = ({
    item,
    withRequestedAt = false,
    witApprovedAt = false
}) => {

    const status = useAssessmentStatus(item);
    const isApproved = useAssessmentIsApproved(item);
    const index = useAssessmentIndex(item);
    const assessmentFiles = useAssessmentFiles(item);
    const { viewFile } = useFileHandler();

    const selfResponsible = useMemo(() => {
        return item.current.assessment.status != 'not_applicable' || item.frequency == 0;
    }, [ item.current.assessment.status, item.frequency ]);


    const parentResponsible = useMemo(() => {
        return item.parent ?item.parent.assessment.status != 'not_applicable' :false;
    }, [ item.parent ]);


    const getStatusText = useMemo(() => {
        const statusStr = GetAssessmentStatusName(status);
        if (selfResponsible) {
            return statusStr;
        }

        const batchCode = parentResponsible ?item.parent?.charge.batchcode :item.previous?.charge.batchcode;

        if (status == 'to_test') {
            return `${ batchCode } ${ statusStr.toLowerCase() }`;
        }

        if (status == 'approved') {
            return `${ batchCode } is ${ statusStr.toLowerCase() }`;
        }

        if (status == 'test_requested') {
            return `${ batchCode } heeft een ${ statusStr.toLowerCase() }`;
        }
        return `${ batchCode } heeft ${ statusStr.toLowerCase() }`;


    }, [ status, selfResponsible, parentResponsible, item.parent?.charge.batchcode, item.previous?.charge.batchcode ]);

    const requestedAt = useMemo(() => {
        if (!withRequestedAt) {
            return undefined;
        }
        const requestedAt = item.current.assessment.requested_at;
        if (!requestedAt) {
            return <small>-</small>;
        }
        return <small>{ datetimeToLocale(requestedAt) }</small>;
    }, [ withRequestedAt, item.current.assessment.requested_at ]);


    const approvedAt = useMemo(() => {
        if (!witApprovedAt) {
            return undefined;
        }
        const approvedAt = item.current.assessment.approved_at;
        if (!approvedAt) {
            return <small>-</small>;
        }
        return <small>{ datetimeToLocale(approvedAt) }</small>;
    }, [ witApprovedAt, item.current.assessment.approved_at ]);


    return (
        <AssessmentRiskItemStructure
            coverage={ <div className={ 'mt-1' }>
                <AssessmentStatusPill isApproved={ isApproved }/>
            </div> }
            risk={ <>
                <p className={ '!text-xs font-medium text-graphite' }>{ item.risk_name }</p>
                <ul className={ 'text-graphite list-disc list-inside' }>
                    { assessmentFiles.map((fileItem, i) => <li key={ i }>
                        <small>
                            <button onClick={ () => viewFile(fileItem) } className={ 'underline' }>
                                { fileItem.batchcode }: { fileItem.name }
                            </button>
                        </small>
                    </li>) }
                </ul>
            </> }
            status={ <small>{ getStatusText }</small> }
            frequency={ <small>{ item.current.assessment.blocked ? <Icon className={'text-carrot relative -top-[0.15rem] pr-1'} materialIconName={'warning'}/> : '' } { index == 0 || item.frequency == 0 ?'nvt' :`${ index }/${ item.frequency }` } </small> }
            approvedAt={ approvedAt }
            requestedAt={ requestedAt }
        />
    );
};

export default AssessmentRiskItem;
