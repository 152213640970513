import React, { FC, useMemo, useState } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { dispatchHttpEvent } from '../../../../events/Http.event';
import ArchiveModal from '../../../../lib/samfe/components/Modal/ArchiveModal';
import { RiskRiskCodeModel, RiskRiskCodeRelationsBluePrint } from './RiskRiskCodeTypes';
import useRiskRiskCode from './useRiskRiskCode';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const RiskRiskCodeTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useRiskRiskCode(parentId);
    const [ currentRiskCode, setCurrentRiskCode ] = useState<RiskRiskCodeModel>();
    const [ openArchiveModal, setOpenArchiveModal ] = useState(false);


    const rows = useMemo((): TableColumns<RiskRiskCodeModel, RiskRiskCodeRelationsBluePrint>[] => [
        {
            header: {
                children: 'Naam',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'risks' }
            rows={ rows }
            http={ {
                // @ts-ignore
                hook: httpHook,
                //@ts-ignore
                searchCols: [ 'name' ],
                sortCol: 'name',
                sortDir: 'ASC',
                filter: {
                    column: 'archived',
                    operator: '=',
                    value: false
                },
                filters: [
                    {
                        displayName: 'Actief',
                        column: 'id',
                        operator: '!=',
                        value: 'null'
                    },
                    {
                        displayName: 'Inactief',
                        column: 'id',
                        operator: '=',
                        value: 'null'
                    }
                ],
                filterConfig: {
                    hideArchived: true
                },
                disableDeArchiving: true
            } }
            callbacks={ [
                {
                    title: 'Activeren',
                    hideForRow: (item) => !!item.risk_risk_code_id,
                    onClick: async(item) => {
                        httpHook.create({
                                risk_id: parentId,
                                risk_code_id: item.risk_code_id!
                            })
                            .then(dispatchHttpEvent);
                    }
                },
                {
                    title: 'Deactiveren',
                    variation: 'destructive',
                    hideForRow: (item) => !item.risk_risk_code_id,
                    onClick: (item) => {
                        setCurrentRiskCode(item);
                        setOpenArchiveModal(true);
                    }
                }
            ] }
            styling={ {
                variation: 'buttons'
            } }
        />

        { openArchiveModal && currentRiskCode && <ArchiveModal
            open={ openArchiveModal }
            setOpen={ setOpenArchiveModal }
            useHttp={ httpHook }
            resourceName={ 'Risicocode' }
            itemName={ currentRiskCode.name }
            id={ currentRiskCode.risk_risk_code_id! }
            onSuccess={ () => {
                setCurrentRiskCode(undefined);
                dispatchHttpEvent();
            } }
            onCancel={ () => setCurrentRiskCode(undefined) }
        /> }
    </>;
};

export default RiskRiskCodeTable;