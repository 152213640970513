import React, { FC, useCallback, useMemo, useState } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import { SortObjectArrayByDate } from '../../lib/samfe/modules/Parse/Object';
import PrinterFileForm from '../printer/PrinterFileForm';
import { FileModel, FileRelationsBluePrint } from './FileTypes';
import { ExtraUploadRef } from './Upload';
import useFile from './useFile';
import useFileHandler from './useFileHandler';


type Props = Omit<ExtendTableProps, 'notification'|'parentId'>&ExtraUploadRef&{
    staticData?: FileModel[]
}
const UploadTable: FC<Props> = ({
    htmlBefore,
    extraColRef,
    extraColName,
    staticData
}): JSX.Element => {

    const httpHook = useFile();
    const { viewFile, downloadFile } = useFileHandler();


    const [ currentFile, setCurrentFile ] = useState<FileModel>();
    const [ showPrintDialog, setShowPrintDialog ] = useState(false);


    const rows = useMemo((): TableColumns<FileModel, FileRelationsBluePrint>[] => {
        const fileRows: TableColumns<FileModel, FileRelationsBluePrint>[] = [
            {
                header: {
                    children: 'Document'
                },
                column: (item) => ({
                    children: item.name
                }),
                type: 'text'
            },
            {
                header: {
                    children: 'Documentcategorie'
                },
                column: (item) => ({
                    children: item.type
                }),
                type: 'text'
            }
        ];

        if (extraColName != undefined) {

            fileRows.push(
                {
                    header: {
                        children: extraColName
                    },
                    column: (item) => {
                        let linkTo = undefined;
                        if (item.col_id && extraColRef) {
                            linkTo = (item: FileModel) => `/${ extraColRef }/${ item.col_id }`;
                        }
                        return ({
                            children: item.col_val,
                            linkTo: linkTo
                        });
                    },
                    type: 'text'
                }
            );
        }


        fileRows.push(
            {
                header: {
                    children: 'Aanmaakdatum'
                },
                column: (item) => ({
                    children: item.created_at
                }),
                type: 'date'
            }
        );
        return fileRows;
    }, [ extraColName, extraColRef ]);

    const postProcessData = useCallback((items: FileModel[]): FileModel[] => {
        return SortObjectArrayByDate(items, 'created_at', 'DESC')
    }, []);

    return <>
        <Table
            id={ 'uploads' }
            rows={ rows }
            staticData={ staticData }
            htmlBefore={ htmlBefore }
            postProcessData={ postProcessData }
            http={ {
                hook: httpHook,
                showAll: true,
                disableDeArchiving: true,
                filterConfig: {
                    hideAllFilters: true
                }
            } }
            forms={ {
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.name }`,
                    resourceName: () => 'Upload'
                },
            } }
            callbacks={ [
                {
                    title: 'Openen',
                    onClick: (item) => {
                        viewFile(item);
                    }
                },
                {
                    title: 'Downloaden',
                    onClick: (item) => {
                        downloadFile(item);
                    }
                },
                {
                    title: 'Afdrukken',
                    onClick: (item) => {
                        setCurrentFile(item);
                        setShowPrintDialog(true);
                    }
                }
            ] }
            styling={{
                variation: 'inline'
            }}
        />

        { showPrintDialog && currentFile?.id && <PrinterFileForm
            open={ showPrintDialog }
            setOpen={ setShowPrintDialog }
            id={ currentFile.id }
        /> }
    </>;
};

export default UploadTable;