import { useMemo, useState } from 'react';
import { dispatchHttpEvent } from '../../../events/Http.event';
import { ArchiveModalProps } from '../../../lib/samfe/components/Modal/ArchiveModal';
import { ExtendFormModalProps } from '../../../lib/samfe/components/Modal/FormModal/FormModal';
import { CurrentTableFormProps } from '../components/stateful/TableForms.component';
import { useTableContext } from '../providers/Table.provider';
import { TableArchiveFormInternalProps, TableFormActionProps } from '../types/TableForm';
import useTableDeArchive from './useTableDearchive';
import useTableForms from './useTableForms';


const useTableFormStates = <T extends object, K extends keyof T, R extends string, V = T[K]>() => {

    const { http: { hook } } = useTableContext<T, R>();
    const { formConfigs, archiveFormConfig } = useTableForms<T, K, R, V>();
    const { hasArchivedResources, deArchiveConfig } = useTableDeArchive<T, keyof T, R, T[keyof T]>();

    const [ currentModel, setCurrentModel ] = useState<T>();
    const [ currentFormConfig, setCurrentFormConfig ] = useState<TableFormActionProps<T, K, V>>();
    const [ showArchiveModal, setShowArchiveModal ] = useState(false);
    const [ showDeArchiveModal, setShowDeArchiveModal ] = useState(false);


    const handleOpenModal = (currentModel: T, formConfig: TableFormActionProps<T, K, V>|TableArchiveFormInternalProps<T, K, V>): void => {
        if ('isArchive' in formConfig) {
            if (hasArchivedResources) {
                setShowDeArchiveModal(true);
            } else {
                setShowArchiveModal(true);
            }
            setCurrentModel(currentModel);
            return;
        }

        const index = formConfigs.findIndex(item => item.title == formConfig.title);
        if (index<0) {
            console.warn('Could not find form state for config', { formConfig });
            return;
        }
        setCurrentFormConfig(formConfigs[index]);
        setCurrentModel(currentModel);
    };

    const handleCloseModal = () => {
        setShowArchiveModal(false);
        setShowDeArchiveModal(false);
        setCurrentFormConfig(undefined);
        setCurrentModel(undefined);
        dispatchHttpEvent()
    };

    const currentFormProps: CurrentTableFormProps<Partial<T>>|undefined = useMemo(() => {
        if (!currentFormConfig || !currentModel) {
            return undefined;
        }

        const formProps = {
            id: currentFormConfig.id?.(currentModel),
            parentId: currentFormConfig.parentId?.(currentModel),
            open: true,
            setOpen: handleCloseModal,
            onSuccess: async (id) => {
                await currentFormConfig?.onSuccess?.(id)
            }
        } as ExtendFormModalProps<Partial<T>>;

        return {
            formProps,
            Form: currentFormConfig.Form
        } as CurrentTableFormProps<T>;
    }, [ currentFormConfig, currentModel ]);


    const archiveModalProps: ArchiveModalProps<T, Partial<T>, R>|undefined = useMemo(() => {
        if (!showArchiveModal || !currentModel || !archiveFormConfig) {
            return undefined;
        }

        return {
            open: true,
            setOpen: () => handleCloseModal(),
            useHttp: hook,
            resourceName: archiveFormConfig.resourceName(currentModel),
            itemName: archiveFormConfig.itemName(currentModel),
            id: archiveFormConfig.id(currentModel),
            onSuccess: async(id) => {
                await archiveFormConfig.onSuccess?.(id);
            }
        } as ArchiveModalProps<T, Partial<T>, R>;
    }, [ showArchiveModal, currentModel, archiveFormConfig, hook ]);


    const deArchiveModalProps: ArchiveModalProps<T, Partial<T>, R>|undefined = useMemo(() => {
        if (!showDeArchiveModal || !currentModel || !deArchiveConfig) {
            return undefined;
        }

        return {
            open: true,
            setOpen: () => handleCloseModal(),
            useHttp: hook,
            resourceName: deArchiveConfig.resourceName(currentModel),
            itemName: deArchiveConfig.itemName(currentModel),
            id: deArchiveConfig.id(currentModel),
            onSuccess: async(id) => {
                await deArchiveConfig.onSuccess?.(id);
            }
        } as ArchiveModalProps<T, Partial<T>, R>;
    }, [ currentModel, hook, showDeArchiveModal, deArchiveConfig ]);


    return {
        handleOpenModal,
        tableFormProps: {
            currentFormProps,
            archiveModalProps,
            deArchiveModalProps
        }
    };
};
export default useTableFormStates;