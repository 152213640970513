import {ChargeModel} from "../charge/ChargeTypes";
import {ArticleModel} from "../article/ArticleTypes";
import {SoftDeleteModel} from "../../lib/samfe/types/ModelTypes";

export type RepackingRelationsBluePrint = (
    | 'parentCharge'
    | 'parentCharge.stock'
    | 'parentCharge.chargeLocations'
    | 'parentCharge.chargeLocations.location'
    | 'parentCharge.chargeLocations.location.group'
    | 'parentArticle.product.productType'
    | 'parentArticle.product'
    | 'parentArticle'
    | 'parentArticle.package.parts'
    | 'parentArticle.package.packageParts.part'
    | 'targetArticle.package.parts'
    | 'targetArticle.package.packageParts.part'
    | 'targetArticle.product'
    | 'targetArticle'
    | 'targetCharge'
    | 'targetCharge.chargeLocations'
    | 'targetCharge.chargeLocations.location'
    | 'targetCharge.chargeLocations.location.group'
)

export type RepackingStatus = 'backorder'|'pending'|'processing'|'processed'|'booked'
export const RepackingStatuses: RepackingStatus[] = ['backorder','pending', 'processing', 'processed','booked'];

export const RepackStatusTranslation = (status?: RepackingStatus) => {
    switch (status) {
        case 'backorder': return 'Backorder'
        case 'pending': return 'Klaar voor uitvullen'
        case 'processing': return 'Bezig met uitvullen'
        case "processed": return 'Klaar voor inboeken'
        case "booked": return 'Ingeboekt'
        default: return 'Onbekend'
    }

}

export type RepackCompany = 'Triple Pharma'|'Zwik'
export const repackCompanies: RepackCompany[] = ['Triple Pharma', 'Zwik'];


export type RepackingFields = {
    parent_article_id?: number,
    parent_charge_id?: number,
    target_article_id?: number,
    target_charge_id?: number,
    batchcode?: string,
    amount?: number,
    repack_date?: string,
    expiration_date?: string,
    status?: RepackingStatus,
    comments: string,
    repacked_by: RepackCompany
}

export type RepackingDto = Partial<RepackingFields>

export type RepackingRelations = {
    parentCharge?: ChargeModel,
    parentArticle?: ArticleModel,

    targetCharge?: ChargeModel,
    targetArticle?: ArticleModel
}

export type RepackingModel = SoftDeleteModel & Partial<RepackingRelations> & RepackingDto & {
    repack_time_in_minutes?: number,
		booked_target_quantity?: number
}
