import React, { FC, useMemo } from 'react';
import { formatProductNumber } from '../product/ProductFunctions';
import PackageForm from './PackageForm';
import { PackageModel, PackageRelationsBluePrint } from './PackageTypes';
import usePackage from './usePackage';
import { getPackageNameFromParts } from './PackageFunctions';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";


type Props = Required<ExtendTableProps>

const PackageTable: FC<Props> = () => {

    const httpHook = usePackage();

    const rows = useMemo((): TableColumns<PackageModel, PackageRelationsBluePrint>[] => [
        {
            header: {
                children: 'Verpakkingsnummer',
                sortCol: 'number'
            },
            column: (item) => ({
                children: formatProductNumber(item.number)
            }),
            style: {
                width: '8rem'
            },
            type: 'id',
        },
        {
          header: {
              children: 'Naam',
          },
          column: (item) => ({
              children: getPackageNameFromParts( item.packageParts, false )
          }),
          type: 'text',
        },
        {
          header: {
              children: 'Afgekorte naam',
          },
          column: (item) => ({
              children: getPackageNameFromParts( item.packageParts, true )
          }),
          type: 'text',
        },
        {
            header: {
                children: 'Aantal onderdelen',
                sortCount: 'packageParts'
            },
            column: (item) => ({
                children: item.packageParts_count
            }),
            type: 'numeric',
        },
        {
            header: {
                children: 'Aantal gelinkte artikelen',
                sortCount: 'articles'
            },
            column: (item) => ({
                children: item.articles_count,
            }),
            type: 'numeric',
        }
    ], []);

    return (<>
            <Table
                id={ 'packages' }
                rows={ rows }
                rowActions={{
                    linkTo: (item) => `${item.id}`
                }}
               
                http={ {
                    hook: httpHook,
                    searchCols: ['name', 'number'],
                    withCount: [ 'packageParts', 'articles' ],
                    sortCol: 'number',
                    with: [
                      'packageParts.part',
                    ]
                } }
                forms={ {
                    edit: {
                        Form: PackageForm,
                        id: (record) => record.id,
                    },
                    archive: {
                        id: (record) => record.id,
                        itemName: (record) => `${ record.name }`,
                        resourceName: () => 'Verpakkingen'
                    },
                } }
            />
        </>
    );
};

export default PackageTable;