import React, { FC } from 'react';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import useRiskAssurance from '../../../riskAssurance/useRiskAssurance';
import BaseElementRiskResourceTable from '../BaseElementRiskResourceTable';
import useElementRiskAssurance from './useElementRiskAssurance';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ElementRiskAssuranceTable: FC<Props> = ({ parentId }): JSX.Element => (
    <BaseElementRiskResourceTable
        resourceName={ 'Risicoborging' }
        usePivotHook={ useElementRiskAssurance }
        foreignKey={ 'risk_assurance_id' }
        useResourceHook={ useRiskAssurance }
        parentId={ parentId }
    />
);

export default ElementRiskAssuranceTable;
