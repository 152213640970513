import React, { FC, useMemo } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { ProducerStandardModel, ProducerStandardRelationsBluePrint } from '../../producer/pivot/producerStandard/ProducerStandardTypes';
import useStandardProducer from './useStandardProducer';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const StandardProducerTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useStandardProducer(parentId);


    const rows = useMemo((): TableColumns<ProducerStandardModel, ProducerStandardRelationsBluePrint>[] => [
        {
            header: {
                children: 'Leverancier'
            },
            column: (item) => ({
                children: item.producer?.name
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'producers' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/producers/${ item.producer_id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: [ 'producer.name' ],
                with: [ 'producer' ]
            } }
            forms={ {
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.producer?.name }`,
                    resourceName: () => 'Leverancier'
                },
                actions: []
            } }
            callbacks={ [] }
        />
    </>;
};


export default StandardProducerTable;