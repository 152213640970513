import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import { ProductRiskReviewModel, ProductRiskReviewRelationsBluePrint } from './ProductRiskReviewTypes';
import useProductRiskReview from './useProductRiskReview';


type Props = Required<ExtendTableProps>

const ProductRiskReviewTable: FC<Props> = (): JSX.Element => {

    const httpHook = useProductRiskReview();


    const rows = useMemo((): TableColumns<ProductRiskReviewModel, ProductRiskReviewRelationsBluePrint>[] => [
        {
            header: {
                children: 'Productevaluatie',
                sortCol: 'number'
            },
            column: (item) => ({
                children: item.number
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Product',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Ontbrekende productrisico\'s',
                sortCol: 'missing_product_risk_count'
            },
            column: (item) => ({
                children: item.missing_product_risk_count
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Ontbrekende grondstofevaluaties',
                sortCol: 'unreviewed_element_risk_count'
            },
            column: (item) => ({
                children: item.unreviewed_element_risk_count
            }),
            type: 'numeric'
        }
    ], []);

    return <>
        <Table
            id={ 'product-risk-reviews' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/products/${ item.id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: ['number', 'name'],
                with: [],
                sortCol: 'number',
                sortDir: 'ASC',
                filters: [
                    {
                        displayName: 'Product risico\'s',
                        column: 'missing_product_risk_count',
                        value: true
                    },
                    {
                        displayName: 'Grondstof evaluaties',
                        column: 'unreviewed_element_risk_count',
                        value: true
                    }
                ]
            } }
            callbacks={ [] }
        />
    </>;
};

export default ProductRiskReviewTable;