import React, { FC, useEffect, useMemo, useState } from 'react';
import { Option } from '../../../../../deprecated/components/Input/InputTypes';
import MultiSelect from '../../../../../deprecated/components/Input/MultiSelect';
import { AttributeFieldProps } from './AttributeProductForm';


const AttributeMultiSelectItem: FC<AttributeFieldProps> = ({ productId, item, onChange }) => {

    const [ selectedIds, setSelectedIds ] = useState<number[]>([]);
    const [ initialized, setInitialized ] = useState(false);

    const attributeOptions = useMemo(() => {
        return (item.attribute_options ?? []).sort((a, b) => a.name.localeCompare(b.name));
    }, [ item.attribute_options ]);


    const attributeProductOptions = useMemo(() => {
        return (item.attribute_product_options ?? []).sort((a, b) => a.name.localeCompare(b.name));
    }, [ item.attribute_product_options ]);


    useEffect(() => {
        setSelectedIds(attributeProductOptions.map(apo => apo.id));
        setInitialized(true);
    }, [ attributeOptions, attributeProductOptions ]);


    const options = useMemo(() => {
        return attributeOptions.map(ao => ({
            displayName: ao.name,
            value: ao.id,
            selected: selectedIds.includes(ao.id)
        }) as Option);
    }, [ attributeOptions, selectedIds ]);


    const handleChange = (optionIds: number[]) => {
        const prevArray = selectedIds.sort();
        const newAttributeOptionIds = optionIds
            .sort();

        if (prevArray.join(',') === newAttributeOptionIds.join(',')) {
            return;
        }
        setSelectedIds(newAttributeOptionIds);
        onChange({
            attribute_id: item.attribute_id,
            product_id: productId,
            attribute_option_ids: newAttributeOptionIds,
            order: item.order
        });
    };

    return (
        <div>
            { initialized && <MultiSelect
                value={ selectedIds }
                overflow={ true }
                name={ item.attribute_name }
                required={ true }
                invalid={ selectedIds.length === 0 }
                options={ options }
                onChange={ (_, optionIds) => handleChange(optionIds as number[]) }
            /> }
        </div>
    );
};

export default AttributeMultiSelectItem;
