import React, { FC, useMemo } from 'react';
import PrinterJobForm from './PrinterJobForm';
import { PrinterJobModel, PrinterJobRelationsBluePrint } from './PrinterJobTypes';
import usePrinterJob from './usePrinterJob';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";


type Props = ExtendTableProps

const PrinterJobTable: FC<Props> = () => {

    const httpHook = usePrinterJob();

    const rows = useMemo((): TableColumns<PrinterJobModel, PrinterJobRelationsBluePrint>[] => [
        {
            header: {
                children: 'Taak',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Gebruik Omschrijving (API)',
                sortCol: 'template'
            },
            column: (item) => ({
                children: item.template
            }),
            type: 'text',
        },
    ], []);

    return (<>
        <Table
            id={ 'printer-jobs' }
            rows={ rows }
            rowActions={{
                linkTo: (item) => `/settings/printer-jobs/${item.id}`
            }}
            http={ {
                hook: httpHook,
                searchCols: ['name', 'template'],
                sortCol: 'name',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: PrinterJobForm,
                    id: (record) => record.id,
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => record.name,
                    resourceName: () => 'Printtaak'
                },
            } }
        />
    </>
    );
};

export default PrinterJobTable;
