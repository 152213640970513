import React from 'react';
import { Link } from 'react-router-dom';
import { NavItem } from '../../Router';
import BasePage from '../../templates/pages/BasePage';


type SettingsRow = {
    title: string,
    items: NavItem[]
}

const compoundSettings: SettingsRow = {
    title: 'Grondstofbeheer',
    items: [
        {
            name: <>Actieve stoffen</>,
            icon: 'local_pharmacy',
            href: '/settings/compound-elements'
        },
        {
            name: <>Grondstof
                <wbr/>
                verbindingen</>,
            icon: 'hub',
            href: '/settings/compounds'
        },
        {
            name: <>Grondstof
                <wbr/>
                categorieën</>,
            icon: 'category',
            href: '/settings/element-categories'
        }
    ]
};

const excipientSettings: SettingsRow = {
    title: 'Hulpstofbeheer',
    items: [
        {
            name: <>Hulp
                <wbr/>
                stoffen</>,
            icon: 'privacy_tip',
            href: '/settings/excipient-elements'
        },
        {
            name: <>Hulpstof
                <wbr/>
                functies</>,
            icon: 'control_point_duplicate',
            href: '/settings/excipients'
        }
    ]
};


const productSettings: SettingsRow = {
    title: 'Productbeheer',
    items: [
        {
            name: <>Product
                <wbr/>
                eigenschappen</>,
            icon: 'add_link',
            href: '/settings/attributes'
        },
        {
            name: <>Product
                <wbr/>
                types</>,
            icon: 'label',
            href: '/settings/product-types'
        },
        {
            name: 'Ingrediënten',
            icon: 'water_drop',
            href: '/settings/ingredients'
        }
    ]
};


/**
 *
 */
const riskSettings: SettingsRow = {
    title: 'Risicobeheer',
    items: [
        {
            name: 'Risico\'s',
            icon: 'gpp_maybe',
            href: '/settings/risks'
        },
        {
            name: 'Risicocodes',
            icon: 'assignment',
            href: '/settings/risk-codes'
        },
        {
            name: 'Risicotypes',
            icon: 'health_and_safety',
            href: '/settings/risk-types'
        },
        {
            name: 'Risicoregulaties',
            icon: 'balance',
            href: '/settings/risk-regulations'
        },
        {
            name: 'Risicoborgingen',
            icon: 'verified_user',
            href: '/settings/risk-assurances'
        }
    ]
};


/**
 *
 */
const producerSettings: SettingsRow = {
    title: 'Ketenborging',
    items: [
        {
            name: <>Leverancier
                <wbr/>
                standaarden</>,
            icon: 'science',
            href: '/settings/producer-standards'
        }
    ]
};

/**
 *
 */
const accountSettings: SettingsRow = {
    title: 'Gebruikers',
    items: [
        {
            name: 'Accounts',
            icon: 'people',
            href: '/settings/accounts'
        },
        {
            name: 'Groepen',
            icon: 'group',
            href: '/settings/groups'
        }
    ]
};


/**
 *
 */
const systemSettings: SettingsRow = {
    title: 'Systeembeheer',
    items: [
        {
            name: 'Systeemstatus',
            icon: 'info',
            href: '/settings/status'
        },
        {
            name: 'Printers',
            icon: 'local_printshop',
            href: '/settings/printers'
        },
        {
            name: 'Printtaken',
            icon: 'receipt_long',
            href: '/settings/printer-jobs'
        }
    ]
};

const Settings: React.FC = (): JSX.Element => {


    const settingsRow = (settings: SettingsRow): JSX.Element => (
        <div className={ 'pb-4' }>
            <div className="border-b border-gray-200 mt-8 pb-5 mb-5">
                <h2 className="text-md font-semibold leading-6 text-aqua">{ settings.title }</h2>
            </div>
            <ul className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7">
                { settings.items.filter(item => item.href !== undefined).map(setting => (
                    <li key={ setting.href } className="col-span-1 flex flex-col relative sm:text-center rounded-md drop-shadow shadow-sm hover:shadow active:shadow-none">
                        <div className="absolute bg-gradient-to-br from-milk to-white left-0 right-0 top-0 bottom-0 z-0 rounded-md"></div>
                        <div className="absolute left-0 right-0 top-0 bottom-0 z-[1] rounded-md shadow-aqua shadow-inner drop-shadow opacity-40 hover:opacity-70"></div>
                        <Link className={ 'block backdrop-blur-sm bg-white bg-opacity-90 hover:bg-opacity-[85%] active:opacity-100 rounded-md h-full p-4 z-[2]' } to={ setting.href! }>
                            <span className="inline w-10 md:w-[3.75rem] select-none text-5xl text-aqua material-icons" aria-hidden="true">{ setting.icon }</span>
                            <h3 className="inline relative left-8 top-2.5 sm:left-auto sm:top-auto sm:block mt-6 text-sm font-medium select-none text-gray-900 break-words">{ setting.name }</h3>
                        </Link>
                    </li>
                )) }
            </ul>
        </div>
    );

    return (
        <BasePage isIndex={ true } title={ 'Gegevensbeheer' } breadcrumb={ 'Gegevensbeheer' }>
            <>
                { settingsRow(compoundSettings) }
                { settingsRow(excipientSettings) }
                { settingsRow(productSettings) }
                { settingsRow(riskSettings) }
                { settingsRow(producerSettings) }
                { settingsRow(accountSettings) }
                { settingsRow(systemSettings) }
            </>

        </BasePage>
    );
};

export default Settings;