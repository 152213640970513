import React, { FC, useEffect, useMemo, useState } from 'react';
import useLoadingScreen from '../../../../../lib/samfe/components/LoadingScreen/useLoadingScreen';
import { ExtendFormModalProps } from '../../../../../lib/samfe/components/Modal/FormModal/FormModal';
import LegacyFormModal from '../../../../../lib/samfe/components/Modal/LegacyFormModal';
import useToaster from '../../../../../lib/samfe/components/Toaster/useToaster';
import useAsyncMemo from '../../../../../lib/samfe/hooks/useAsyncMemo';
import useAxios from '../../../../../lib/samfe/modules/Http/useAxios';
import { ListResponse } from '../../../../../lib/samfe/modules/Http/useHttp';
import { AttributeType } from '../../../../attribute/AttributeTypes';
import { ParsedAttributeProductItem } from '../useAttributeProduct';
import AttributeInputItem from './AttributeInputItem';
import AttributeMultiSelectItem from './AttributeMultiSelectItem';
import AttributeSelectItem from './AttributeSelectItem';
import AttributeTextAreaItem from './AttributeTextAreaItem';


type PutItem = {
    attribute_id: number;
    product_id: number;
    attribute_option_ids?: number[]|null;
    attribute_value?: string|null;
    order: number;
};

type UpdatePayload = {
    items: PutItem[]
}

export type AttributeFieldProps = {
    productId: number
    item: ParsedAttributeProductItem,
    onChange: (putItem: PutItem) => void
}

const AttributeProductForm: FC<ExtendFormModalProps<ParsedAttributeProductItem[]>> = ({
    parentId,
    open,
    setOpen,
    onSuccess
}): JSX.Element => {

    const { setLoadingScreenProps } = useLoadingScreen();
    const { setToasterProps } = useToaster();
    const { get, put } = useAxios();
    const productId = useMemo(() => parentId, [ parentId ]);

    const [ putItems, setPutItems ] = useState<PutItem[]>([]);
    const [ shouldSave, setShouldSave ] = useState(false);

    const initialFormItems = useAsyncMemo(async() => {
        if (!productId) {
            return [];
        }
        return await get<ListResponse<ParsedAttributeProductItem>>(`products/${ productId }/parsed/attributes/form-values`)
            .then(res => {
                const items = res.data.items;
                return items.map(item => {
                    if (!([ 'select', 'multiselect' ] as AttributeType[]).includes(item.attribute_type)) {
                        return item;
                    }
                    return {
                        ...item,
                        attribute_product_options: (item.attribute_product_options ?? [])
                            .filter((option, index, self) =>
                                index === self.findIndex(t => t.id === option.id)
                            )
                    };
                });
            });
    }, [ productId ], []);

    useEffect(() => {
        if (!productId) {
            return;
        }

        setPutItems(initialFormItems.map(item => {

            let optionIds: number[]|null = null;
            let value: string|null = null;
            if (([ 'multiselect', 'select' ] as AttributeType[]).includes(item.attribute_type)) {
                optionIds = (item.attribute_product_options ?? []).map(option => option.id);
            } else {
                value = item.attribute_value;
            }
            return {
                attribute_id: item.attribute_id,
                product_id: productId,
                attribute_option_ids: optionIds,
                attribute_value: value,
                order: item.order
            };
        }));
    }, [ initialFormItems ]);

    const handleChange = (item: PutItem) => {
        const putItemIndex = putItems.findIndex(putItem => putItem.attribute_id == item.attribute_id);
        if (putItemIndex<0) {
            // Create
            setPutItems([ ...putItems, item ]);
            return;
        }
        // update
        const newPutItems = [ ...putItems ];
        newPutItems[putItemIndex] = item;
        setPutItems(newPutItems);
    };


    const handleSave = async(): Promise<boolean> => {
        return await put(`products/${ productId }/parsed/attributes`, { items: putItems } as UpdatePayload)
            .then(res => [ 200, 201 ].includes(res.status))
            .catch(() => false);
    };

    /**
     * Check if PUT request is succeeded.
     *
     * @param {boolean} isSaved
     * @returns {void}
     */
    const handleSuccess = (isSaved: boolean): void => {
        setToasterProps({
            show: true,
            type: isSaved ?'success' :'error',
            title: isSaved ?'Opgeslagen' :'Opslaan mislukt'
        });

        if (isSaved) {
            onSuccess?.(undefined);
            setOpen(false);
            return;
        }
        setShouldSave(false);
    };

    /**
     * Handle call from LegacyFormModal save button.
     * LegacyFormModal resets its state by itself.
     * ignore if shouldSave is false.
     */
    useEffect(() => {
        if (!shouldSave) {
            return;
        }
        if (putItems.length<1) {
            handleSuccess(true);
            return;
        }

        setLoadingScreenProps({ show: true });
        handleSave()
            .then(handleSuccess)
            .finally(() => setLoadingScreenProps({ show: false }));
    }, [ shouldSave ]);

    return (
        <LegacyFormModal
            open={ open }
            setOpen={ setOpen }
            resource={ 'Product eigenschappen' }
            setShouldSave={ setShouldSave }
            isCreate={ false }
            className={ '!w-full md:!w-[80vw] !max-w-[64rem]' }
        >
            { productId !== undefined && initialFormItems.map((item, i) => <div key={ i } className={ 'grid grid-cols-1 lg:grid-cols-12 my-2' }>
                <div className="col-span-4 flex items-center">
                    <p>
                        { item.attribute_name }<br/>
                        <small>{ item.attribute_description }</small>
                    </p>
                </div>
                <div className="col-span-8">
                    { item.attribute_type == 'multiselect' && <AttributeMultiSelectItem
                        productId={ productId }
                        item={ item }
                        onChange={ handleChange }
                    /> }
                    { item.attribute_type == 'select' && <AttributeSelectItem
                        productId={ productId }
                        item={ item }
                        onChange={ handleChange }
                    /> }
                    { item.attribute_type == 'textarea' && <AttributeTextAreaItem
                        productId={ productId }
                        item={ item }
                        onChange={ handleChange }
                    /> }
                    { ([ 'text', 'number' ] as AttributeType[]).includes(item.attribute_type) && <AttributeInputItem
                        productId={ productId }
                        item={ item }
                        onChange={ handleChange }
                    /> }
                </div>
            </div>) }
        </LegacyFormModal>
    );
};

export default AttributeProductForm;
