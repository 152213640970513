import moment from 'moment';
import React, { Dispatch, FC, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import Table from '../../components/table';
import { TableColumns } from '../../components/table/types/TableRow';
import { dispatchHttpEvent, useHttpEvent } from '../../events/Http.event';
import Input from '../../lib/samfe/components/Form/Generic/Input';
import Icon from '../../lib/samfe/components/Icon/Icon';
import { thousandSeparator, toEuroString } from '../../lib/samfe/modules/Parse/Number';
import { classNames } from '../../lib/samfe/modules/Parse/String';
import usePurchaseRow from '../purchase/pivot/purchaseRow/usePurchaseRow';
import { PurchaseStatusTranslation } from '../purchase/PurchaseTypes';
import { RepackStatusTranslation } from '../repacking/RepackingTypes';
import useRepackingPivotless from '../repacking/useRepackingPivotless';
import { IncomingGoodsModel, PurchaseRowItem, RepackItem } from './IncomingGoodsTypes';
import 'moment/locale/nl';
import useIncomingGoods from './useIncomingGoods';

const GroupItem: FC<{
    id: ReactNode,
    deliveryDate: ReactNode,
    supplier: ReactNode,
    productNumber: ReactNode,
    productName: ReactNode,
    price: ReactNode,
    orderedAmount: ReactNode,
    deliveredAmount: ReactNode,
    orderDate: ReactNode,
    status: ReactNode,
    note: ReactNode,
    linkTo: string
}> = (props) => <>
    <div className={ 'grid grid-cols-12 min-w-[128rem] gap-x-8' }>
        <div className={ 'col-span-6 grid grid-cols-12' }>
            <div className={ 'col-span-2' }>
               { props.linkTo ? <a className='underline decoration-aqua text-aqua underline-offset-2 decoration-[0.03125rem]' href={ props.linkTo }>{ props.id }</a> : props.id }
            </div>
            <div className={ 'col-span-2' }>{ props.deliveryDate }</div>
            <div className={ 'col-span-3' }>
                <div className={ 'text-wrap' }>{ props.supplier }</div>
            </div>
            <div className={ 'col-span-2' }>{ props.productNumber }</div>
            <div className={ 'col-span-3' }>
                <div className={ 'text-wrap' }>{ props.productName }</div>
            </div>
        </div>
        <div className={ 'col-span-4 grid grid-cols-5' }>
            <div>{ props.price }</div>
            <div>{ props.orderedAmount }</div>
            <div>{ props.deliveredAmount }</div>
            <div>{ props.orderDate }</div>
            <div>{ props.status }</div>
        </div>
        <div className={ 'col-span-2' }>
            <div className={ 'text-wrap' }>{ props.note }</div>
        </div>
    </div>
</>;


const PurchaseRowGroupItem: FC<{
    purchaseRow: PurchaseRowItem,
    setHighlightChangedRowId: Dispatch<SetStateAction<number|undefined>>
}> = ({ purchaseRow, setHighlightChangedRowId }) => {


    const purchaseRowHook = usePurchaseRow(purchaseRow.purchase_id);
    const httpDispatched = useHttpEvent()

    const [ editMode, setEditMode ] = useState(false);
    const [ currentConfirmedDeliveryDate, setCurrentConfirmedDeliveryDate ] = useState(purchaseRow.confirmed_delivery_date);

    useEffect(() => {
        setCurrentConfirmedDeliveryDate(purchaseRow.confirmed_delivery_date);
    }, [ purchaseRow.confirmed_delivery_date, httpDispatched ]);


    const deliveryConfirmed = useMemo(() => {
        return currentConfirmedDeliveryDate != null && currentConfirmedDeliveryDate != '';
    }, [ currentConfirmedDeliveryDate ]);

    const deliveryDate = useMemo(() => {
        if (deliveryConfirmed) {
            return currentConfirmedDeliveryDate;
        }
        return purchaseRow.expected_delivery_date;
    }, [ deliveryConfirmed, purchaseRow.expected_delivery_date, currentConfirmedDeliveryDate ]);

    const formattedDeliveryDate = useMemo(() => {
        return moment(deliveryDate).format('DD-MM-YYYY');
    }, [ deliveryDate ]);


    const handleSave = async() => {
        //@ts-ignore
        await purchaseRowHook.update(purchaseRow.purchase_row_id, { confirmed_delivery_date: currentConfirmedDeliveryDate })
            .then(() => {
                setEditMode(false);
                setHighlightChangedRowId(purchaseRow.purchase_row_id);
            })
            .finally(() => dispatchHttpEvent());
    };

    const handleCancel = () => {
        setCurrentConfirmedDeliveryDate(purchaseRow.confirmed_delivery_date)
        setEditMode(false)
    }

    return <div className={ classNames('pt-1', editMode && '-mb-2.5') }>
        <GroupItem
            id={ purchaseRow.purchase_id }
            deliveryDate={ <div>
                { !editMode && <div>
                    <span className={ classNames(!deliveryConfirmed && 'opacity-50') }>{ formattedDeliveryDate }</span>
                    <button onClick={ () => setEditMode(true) }>
                        <Icon className={ 'text-xs ml-2' } materialIconName={ 'edit' }/>
                    </button>
                </div> }
                { editMode && <div className={ 'inline-flex items-center content-center' }>
                    <Input
                        label={ '' }
                        name={ 'date' }
                        type={ 'date' }
                        className={ '!w-28 !text-xs !py-1 !-mt-2.5' }
                        value={ currentConfirmedDeliveryDate ?? undefined }
                        onChange={ (v) => setCurrentConfirmedDeliveryDate(`${ v }`) }
                    />

                    <button className={ ' ml-2 -mt-2' } onClick={ handleSave }>
                        <Icon className={ 'text-xs' } materialIconName={ 'check' }/>
                    </button>
                    <button className={ 'ml-2 -mt-2' } onClick={ handleCancel}>
                        <Icon className={ 'text-xs' } materialIconName={ 'close' }/>
                    </button>
                </div> }

            </div> }
            supplier={ purchaseRow.producer_name }
            productNumber={ purchaseRow.article_number }
            productName={ purchaseRow.product_name }
            price={ toEuroString(purchaseRow.price_per_amount) }
            orderedAmount={ thousandSeparator(purchaseRow.quantity) }
            deliveredAmount={ thousandSeparator(purchaseRow.booked_quantity ?? 0) }
            orderDate={ purchaseRow.sent_at ?moment(purchaseRow.sent_at).format('DD-MM-YYYY') :'-' }
            status={ PurchaseStatusTranslation(purchaseRow.status) }
            note={ purchaseRow.comments }
            linkTo={`/purchases/${ purchaseRow.purchase_id }`}
        />
    </div>;
};

const RepackGroupItem: FC<{
    repackOrder: RepackItem,
    setHighlightChangedRepackId: Dispatch<SetStateAction<number|undefined>>
}> = ({ repackOrder, setHighlightChangedRepackId }) => {

    const repackHook = useRepackingPivotless()
    const httpDispatched = useHttpEvent()

    const [ editMode, setEditMode ] = useState(false);
    const [ repackDate, setRepackDate ] = useState((repackOrder.repack_date??'').split(' ')[0]);

    useEffect(() => {
        setRepackDate(repackOrder.repack_date);
    }, [ repackOrder.repack_date, httpDispatched ]);

    const formattedRepackDate = useMemo(() => {
        return moment(repackDate).format('DD-MM-YYYY')
    }, [ repackDate ]);


    const handleSave = async() => {
        await repackHook.update(repackOrder.id, { repack_date: repackDate })
            .then(() => {
                setEditMode(false);
                setHighlightChangedRepackId(repackOrder.id);
            })
            .finally(() => dispatchHttpEvent());
    };

    const handleCancel = () => {
        setRepackDate(repackOrder.repack_date)
        setEditMode(false)
    }

    return <div className={ classNames('pt-1', editMode && '-mb-2.5') }>
        <GroupItem
            id={ repackOrder.id }
            deliveryDate={ <div>
                { !editMode && <div>
                    <span>{ formattedRepackDate }</span>
                    <button onClick={ () => setEditMode(true) }>
                        <Icon className={ 'text-xs ml-2' } materialIconName={ 'edit' }/>
                    </button>
                </div> }
                { editMode && <div className={ 'inline-flex items-center content-center' }>
                    <Input
                        label={ '' }
                        name={ 'date' }
                        type={ 'date' }
                        className={ '!w-28 !text-xs !py-1 !-mt-2.5' }
                        value={ repackDate ?? undefined }
                        onChange={ (v) => setRepackDate(`${ v }`) }
                    />
                    <button className={ ' ml-2 -mt-2' } onClick={ handleSave }>
                        <Icon className={ 'text-xs' } materialIconName={ 'check' }/>
                    </button>
                    <button className={ 'ml-2 -mt-2' } onClick={ handleCancel }>
                        <Icon className={ 'text-xs' } materialIconName={ 'close' }/>
                    </button>
                </div> }

            </div> }
            supplier={ repackOrder.producer_name }
            productNumber={ repackOrder.target_article_number }
            productName={ repackOrder.product_name }
            price={ toEuroString(repackOrder.price_per_amount ?? 0) }
            orderedAmount={ thousandSeparator(repackOrder.amount) }
            //deliveredAmount={ thousandSeparator(repackOrder.amount) }
            deliveredAmount={ 0 }
            orderDate={ formattedRepackDate }
            status={ RepackStatusTranslation(repackOrder.status) }
            note={ repackOrder.comments }
            linkTo={ '' }
        />
    </div>;
};

const GroupResource: FC<{
    groupData: IncomingGoodsModel,
    highlightPurchaseRowIdState: [ number|undefined, Dispatch<SetStateAction<number|undefined>> ],
    highlightRepackIdState: [ number|undefined, Dispatch<SetStateAction<number|undefined>> ]
}> = ({ groupData, highlightPurchaseRowIdState, highlightRepackIdState }) => {

    const [ highlightChangedRowId, setHighlightChangedRowId ] = useMemo(() => highlightPurchaseRowIdState, [ highlightPurchaseRowIdState ]);
    const [ highlightChangedRepackId, setHighlightChangedRepackId ] = useMemo(() => highlightRepackIdState, [ highlightRepackIdState ]);

    const title = useMemo(() => {
        const weekNumber: number = groupData.week_number;
        const year: number = groupData.year;

        const startOfWeek = moment().year(year).isoWeek(weekNumber).isoWeekday(1).locale('nl');
        const endOfWeek = moment().year(year).isoWeek(weekNumber).isoWeekday(5).locale('nl');

        const startDate: string = `${ startOfWeek.date() } ${ startOfWeek.format('MMMM') }`;
        const endDate: string = `${ endOfWeek.date() } ${ endOfWeek.format('MMMM') }`;

        return `${ startDate } t/m ${ endDate }`;
    }, [ groupData.week_number, groupData.year ]);


    type GroupItem = {
        type: 'purchaseRow',
        date: string
        data: PurchaseRowItem
    }|{
        type: 'repackOrder',
        date: string
        data: RepackItem
    }


    const items = useMemo(() => {
        const groupItems: GroupItem[] = [];

        groupItems.push(...groupData.purchase_rows.map((pr): GroupItem => ({
            type: 'purchaseRow',
            date: pr.confirmed_delivery_date != null && pr.confirmed_delivery_date != '' ?pr.confirmed_delivery_date :pr.expected_delivery_date,
            data: pr
        })));

        groupItems.push(...groupData.repack_orders.map((rp): GroupItem => ({
            type: 'repackOrder',
            date: rp.repack_date,
            data: rp
        })));

        return groupItems.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());
    }, [ groupData.purchase_rows, groupData.repack_orders ]);


    return <>
        <div className={ 'bg-neutral-100/20 shadow -ml-12 -mr-4 py-3 pl-12 pr-4 font-medium text-aqua -mt-4' }>
            <GroupItem
                id={ <>Week { groupData.week_number } { groupData.year }</> }
                deliveryDate={ title }
                supplier={ '' }
                productNumber={ '' }
                productName={ '' }
                price={ '' }
                orderedAmount={ '' }
                deliveredAmount={ '' }
                orderDate={ '' }
                status={ '' }
                note={ '' }
                linkTo={ '' }
            />
        </div>

        <div className={ '-ml-12 -mr-4 pt-0' }>
            <div className="grid gap-y-2 divide-y">
                { items.map((item, i) =>
                    <div
                        key={ i }
                        className={ classNames(
                            'pl-12 pr-4 pt-1 transition',
                            i == 0 && items.length == 1 && 'pt-2',
                            item.type == 'purchaseRow' && highlightChangedRowId == item.data.purchase_row_id && 'bg-yellow-200/75 pb-2 -mb-2',
                            item.type == 'repackOrder' && highlightChangedRepackId == item.data.id && 'bg-yellow-200/75 pb-2 -mb-2',
                        ) }
                    >
                        { item.type == 'purchaseRow' && <PurchaseRowGroupItem
                            purchaseRow={ item.data }
                            setHighlightChangedRowId={ setHighlightChangedRowId }
                        /> }
                        { item.type == 'repackOrder' && <RepackGroupItem
                            repackOrder={ item.data }
                            setHighlightChangedRepackId={ setHighlightChangedRepackId }
                        /> }
                    </div>
                ) }
            </div>
        </div>
    </>;
};


const IncomingGoodsTable: FC = () => {

    const httpHook = useIncomingGoods();
    const httpDispatched = useHttpEvent();
    const [ highlightChangedPurchaseRowId, setHighlightChangedRowId ] = useState<number>();
    const [ highlightChangedRepackId, setHighlightChangedRepackId ] = useState<number>();

    useEffect(() => {
        if (!highlightChangedPurchaseRowId) {
            return;
        }
        setTimeout(() => {
            setHighlightChangedRowId(undefined);
        }, 5000);
    }, [ highlightChangedPurchaseRowId ]);

    useEffect(() => {
        if (!highlightChangedRepackId) {
            return;
        }
        setTimeout(() => {
            setHighlightChangedRepackId(undefined);
        }, 5000);
    }, [ highlightChangedRepackId ]);


    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    const reOrder = useCallback((items: IncomingGoodsModel[]): IncomingGoodsModel[] => {

        const newItems: IncomingGoodsModel[] = [];

        items.forEach(item => {

            const newItem: IncomingGoodsModel = {
                week_number: item.week_number,
                year: item.year,
                purchase_rows: [],
                repack_orders: []
            };

            newItem.purchase_rows.push(...item.purchase_rows
                .sort((a, b) => {
                    if (a.purchase_id == b.purchase_id) {
                        return 0
                    }
                    return a.purchase_id < b.purchase_id ? -1 : 1
                })
                .sort((a, b) => {
                let aVal = a.expected_delivery_date;
                let bVal = b.expected_delivery_date;

                if (!!a.confirmed_delivery_date && a.confirmed_delivery_date != '') {
                    aVal = a.confirmed_delivery_date;
                }

                if (!!b.confirmed_delivery_date && b.confirmed_delivery_date != '') {
                    bVal = b.confirmed_delivery_date;
                }

                return moment(aVal).valueOf() - moment(bVal).valueOf();
            }));

            newItem.repack_orders.push(...item.repack_orders
                .sort((a, b) => {
                    if (a.id == b.id) {
                        return 0
                    }
                    return a.id < b.id ? -1 : 1
                })
                .sort((a, b) => {
                let aVal = a.repack_date;
                let bVal = b.repack_date;
                return moment(aVal).valueOf() - moment(bVal).valueOf();
            }));

            newItems.push(newItem);
        });

        return newItems;
    }, [ httpDispatched, highlightChangedPurchaseRowId, highlightChangedRepackId ]);

    //noinspection com.intellij.reactbuddy.ExhaustiveDepsInspection
    const rows = useMemo((): TableColumns<IncomingGoodsModel, ''>[] => [
        {
            header: {
                children: <GroupItem
                    id={ 'Inkooporder' }
                    deliveryDate={ 'Datum (bevestigd)' }
                    supplier={ 'Leverancier' }
                    productNumber={ 'Artikel' }
                    productName={ 'Product' }
                    price={ 'Prijs (verwacht)' }
                    orderedAmount={ 'Aantal (besteld)' }
                    deliveredAmount={ 'Aantal (geleverd)' }
                    orderDate={ 'Besteldatum' }
                    status={ 'Status' }
                    note={ 'Aanvullende informatie' }
                    linkTo={ '' }
                />
            },
            column: (item) => ({
                children: <GroupResource
                    groupData={ item }
                    highlightPurchaseRowIdState={ [ highlightChangedPurchaseRowId, setHighlightChangedRowId ] }
                    highlightRepackIdState={[ highlightChangedRepackId, setHighlightChangedRepackId ]}
                />
            }),
            type: 'text',
            style: {
                background: 'white'
            }
        }
    ], [
        highlightChangedPurchaseRowId,
        highlightChangedRepackId
    ]);

    return (
        <Table
            id={ 'incoming-goods' }
            rows={ rows }
            postProcessData={ reOrder }
            http={ {
                hook: httpHook,
                showAll: true,
                sortDir: 'DESC',
                filterConfig: {
                    hideAllFilters: true
                }
            } }
        />
    );
};

export default IncomingGoodsTable;
