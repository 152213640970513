import React, { FC, useState } from 'react';
import ShowPage from '../../templates/pages/ShowPage';
import PrinterJobTable from '../printerJob/PrinterJobTable';
import PrinterForm from './PrinterForm';
import { PrinterModel } from './PrinterTypes';
import usePrinter from './usePrinter';


const PrinterShow: FC = (): JSX.Element => {

    const [ currentPrinter, setCurrentPrinter ] = useState<PrinterModel>();

    return (
        <ShowPage
            title={ currentPrinter?.name }
            breadcrumb={ currentPrinter?.name }
            setCurrentModel={ setCurrentPrinter }
            FormModal={ PrinterForm }
            httpHook={ usePrinter }
            relations={ [
                'printJobs'
            ] }

            tabs={ currentPrinter ?[
                {
                    name: 'Printtaken',
                    id: 'overview',
                    element: <PrinterJobTable />
                }
            ] :[] }
        />
    );
};
export default PrinterShow;