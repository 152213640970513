import useAxios from '../../lib/samfe/modules/Http/useAxios';
import {FileType, MailDocumentRequest} from './DocumentTypes';
import { FileModel } from './FileTypes';

import { API_URL } from '../../config/config';



type UseFileHandler = {
    downloadFile: (file: FileModel) => void,
    downloadFileByUrl: (url: string, fileName: string, extension: string) => void,
    openFile: (data: string, contentType: string, fileName?: string) => void,
    getDocument: (id: number|string|undefined, type: FileType, params?: string[]) => void,
    mailFile: (id: number, type: FileType,  data: MailDocumentRequest) => Promise<void>,
    viewFile: (file: FileModel) => void,
}

// @todo open file straight from api url (cookie support works)
const useFileHandler = (): UseFileHandler => {

    const { get, post } = useAxios();

    /**
     *
     * @param data
     * @param contentType
     * @param fileName
     * @returns {void}
     */
    const openFile = (data: string, contentType: string, fileName?: string): void => {
        const blob = new Blob([ data ], { type: contentType });
        const fileURL = window.URL.createObjectURL(blob);

        if (!fileName) {
            window.open(fileURL);
            return;
        }

        // create <a> tag dynamically
        let fileLink = document.createElement('a');
        fileLink.href = fileURL;

        // it forces the name of the downloaded file
        fileLink.download = fileName;

        // triggers the click event
        fileLink.click();
    };



    /**
     *
     * @param {FileType|undefined} type
     * @returns {string|undefined}
     */
    const getTypeNameOrUndefined = (type?: FileType): string|undefined => {
        switch (type) {
            case 'productdossier':
                return 'Productdossier voor product';
            case 'gira':
                return 'Gira voor klant';
            case 'coa':
                return 'COA voor charge';
            case 'packslip':
                return 'Pakbon voor verkooporder';
            case 'stock-csv':
                return 'GA voorraad export';
            case 'repackage':
                return 'Productietaak';
            case 'charge-label':
                return 'Charge label';
            case 'sticker':
                return 'Sticker';
            case 'pallet-sticker':
                return 'Palletsticker';
        }
        return undefined;
    };


    /**
     * Get name of generated pdf.
     * @param file
     * @param id
     * @returns {string|undefined}
     */
    const getDocumentName = (file?: FileType, id?: string|number): string|undefined => {
        if (file) {
            let type = getTypeNameOrUndefined(file);
            if (type === undefined) {
                // If no predefined type was found, capitalize the first letter of each word:
                type = file.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
            }
            const suffix = ` ${ id }` ?? '';
            return `${ type }${ suffix }`;
        }
        return undefined;
    };


    /**
     * For generated pdfs.
     *
     * @param id
     * @param file
     * @param params
     * @returns {void}
     */
    const downloadDocument = <T extends FileType>(id: number|string|undefined, file: T, params?: string[]): void => {
        const paramStr = params ?`?${ params.join('&') }` :'';
        const idArg = typeof id === 'number' ?id :'';
        get<string>(`documents/${ file }/${ idArg }${ paramStr }`, undefined, { responseType: 'blob' })
            .then(r => openFile(r.data, r.headers['content-type'], getDocumentName(file, id)));
    };


    /**
     * For generated pdfs.
     *
     * @param id
     * @param type
     * @param params
     * @returns {void}
     */
    const getDocument = (id: number|string|undefined, type: FileType, params?: string[]): void => downloadDocument<FileType>(id, type, params);


    /**
     * For generated pdfs.
     *
     * @param {number} id
     * @param {FileType} type
     * @param {MailDocumentRequest} data
     * @returns {Promise<void>}
     */
    const mailFile = async (id: number, type: FileType, data: MailDocumentRequest): Promise<void> => {
        await post(`documents/${ type }/${ id }/mail`, data);
    }


    /**
     * For misc files.
     *
     * @returns {void}
     * @param url
     * @param fileName
     * @param extension
     */
    const downloadFileByUrl = (url: string, fileName: string, extension: string = 'pdf'): void => {
        get<string>(url, undefined, { responseType: 'blob' })
            .then(r => {
                openFile(r.data, r.headers['content-type'], `${fileName}.${extension}`);
            });
    };

    /**
     * For uploaded files.
     *
     * @param {FileModel} file
     * @returns {void}
     */
    const downloadFile = (file: FileModel): void => {
        get<string>(`file/${ file.id }`, undefined, { responseType: 'blob' })
            .then(r => {
                openFile(r.data, r.headers['content-type'], `${file.name}.${file.extension?.toLowerCase() ?? 'pdf'}`);
            });
    };

    /**
     * For viewing uploaded files.
     *
     * @param {FileModel} file
     * @returns {void}
     */
    const viewFile = (file: FileModel): void => {
        setTimeout(() => {
            window.open(`${API_URL}/api/v1/file/blob/${file.id}`, '_blank', 'noreferrer')
        }, 200);
    };


    return {
        openFile,
        downloadFile,
        downloadFileByUrl,
        getDocument,
        mailFile,
        viewFile
    };
};

export default useFileHandler;