import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import ProductTypeForm from './ProductTypeForm';
import { ProductTypeModel, ProductTypeRelationsBluePrint } from './ProductTypeTypes';
import useProductType from './useProductType';


type Props = Required<ExtendTableProps>

const ProductTypeTable: FC<Props> = () => {

    const httpHook = useProductType();

    const rows = useMemo((): TableColumns<ProductTypeModel, ProductTypeRelationsBluePrint>[] => [
        {
            header: {
                children: 'Producttype',
                sortCol: 'name'
            },
            column: (item) => ({
                children: <>
                    <span>{ item.name }</span>
                    <small>&nbsp;{ item.plural ?`(${ item.plural })` :'' }</small>
                </>

            }),
            type: 'element'
        },
        {
            header: {
                children: 'Aantal gelinkte producten',
                sortCount: 'products'
            },
            column: (item) => ({
                children: item.products_count
            }),
            type: 'numeric'
        }
    ], []);

    return (
        <Table
            id={ 'product-types' }
            rows={ rows }
            rowActions={{
                linkTo: (item) => `${item.id}`
            }}
            http={ {
                hook: httpHook,
                searchCols: [ 'name', 'plural' ],
                withCount: [ 'products' ],
                sortCol: 'name',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: ProductTypeForm,
                    id: (record) => record.id
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (item) => item.name,
                    resourceName: () => 'Producttype',
                    disableForRow: (item) => item.products_count != 0
                }
            } }
        />
    );
};

export default ProductTypeTable;
