import React, { FC, useEffect, useState } from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { filterIds } from '../../../../lib/samfe/modules/Parse/Url';
import { modelNotArchived } from '../../../../lib/samfe/types/ModelTypes';
import { getFullArticleName } from '../../../article/ArticleFunctions';
import { ArticleModel } from '../../../article/ArticleTypes';
import { ArticleProducerDto, ArticleProducerModel, ArticleProducerRelationsBluePrint } from '../../../article/pivot/ArticleProducer/ArticleProducerTypes';
import useArticle from '../../../article/useArticle';
import useProducer from '../../useProducer';
import useProducerArticle from './useProducerArticle';


/**
 * Parent ID is producer ID.
 * ID is pivot ID
 *
 * @param id
 * @param open
 * @param setOpen
 * @param onSuccess
 * @param parentId
 * @constructor
 */
/** */
const ProducerArticleForm: FC<ExtendFormModalProps<ArticleProducerDto>> = ({
    id,
    open,
    setOpen,
    onSuccess,
    parentId
}): JSX.Element => {

    const producer = useProducer();
    const producerArticle = useProducerArticle(parentId);
    const [ initialArticleProducer, setInitialArticleProducer ] = useState<ArticleProducerModel|undefined>(undefined);
    const [ currentArticle, setCurrentArticle ] = useState<ArticleModel|undefined>(undefined);
    const [ customName, setCustomName ] = useState<string>();
    const [ occupiedIds, setOccupiedIds ] = useState<number[]>([]);
    const currentArticleNumber = () => id ?<span className={ 'text-graphite font-medium' }>{ initialArticleProducer?.article?.number }</span> :<></>;

    /**
     *
     */
    const shape = (): Shape<ArticleProducerDto> => ({

        producer_id: Yup.number()
            .label('Leverancier')
            .hidden(true)
            .controlType('input')
            .defaultValue(parentId)
            .inputType('text'),


        article_id: Yup.number()
            .label('Artikel')
            .hidden(id !== undefined)
            .defaultValue(id)
            .required()
            .controlType('selectSearch')
            .selectSearchConfig({
                expectsInitialModel: false,
                useHook: useArticle,
                onChange: setCurrentArticle,
                searchOptions: {
                    searchCols: [ 'number' ],
                    relationSearch: [
                        {
                            relationCol: 'product.name'
                        }
                    ],
                    valueCol: 'id',
                    relations: [ 'product', 'package.parts' ],
                    limit: 'all',
                    displayName: getFullArticleName,
                    filter: `active=1${ occupiedIds.length>0 ?',' :'' }${ filterIds({ ids: occupiedIds, exclude: true }) ?? '' }`
                }
            }),

        custom_name: Yup.string()
            .label('Artikelnaam bekend bij Leverancier')
            .controlType('input')
            .defaultValue(customName ?? '')
            .handleValueChange(setCustomName)
            .inputType('text')
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<ArticleProducerDto>(shape());

    // @todo reset states on re init
    const initializer = async() => {

        const occupiedArticleIds: number[] = await producer.getItem(parentId, { with: [ 'articleProducers' ] })
            .then(producer => (producer?.articleProducers ?? [])
                .filter(ap => modelNotArchived(ap.archived))
                .map(ap => ap.article_id!)
            );
        // @fixme does not match well
        setOccupiedIds(occupiedArticleIds);
        await producerArticle
            .getItem(id, { with: [ 'article' ] })
            .then(ap => {
                setInitialArticleProducer(ap);
                setCustomName(ap?.custom_name);
                return ap;
            });
        setShape(shape());
    };


    useEffect(() => {
        setShape(shape());
    }, [ initialArticleProducer, occupiedIds, customName ]);

    /**
     *
     */
    const { formikConfig, formFields } = useForm<ArticleProducerModel, ArticleProducerDto, ArticleProducerRelationsBluePrint>({
        id,
        skipInitialFetch: true,
        parentId,
        parentKey: 'producer_id',
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useProducerArticle,
        onSuccess: onSuccess,
        morphPayload: (_, dto) => {
            const newDto = { ...dto };

            if (id !== undefined && (customName ?? '').replaceAll(' ', '') === '') {
                //@ts-ignore
                newDto.custom_name = null;
            } else {
                newDto.custom_name = customName
            }

            return newDto;
        }
    });

    return <FormModal
        id={ id }
        parentId={ parentId }
        resource={ 'Artikel van Leverancier' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        htmlBeforeForm={ currentArticleNumber() }
        formFields={ formFields }
    />;
};

export default ProducerArticleForm;
