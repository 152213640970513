import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { classNames } from '../../modules/Parse/String';
import Skeleton from '../Skeleton/Skeleton';


export type Stat = {
    name: string,
    value?: ReactNode,
    change?: string,
    changeType?: 'positive'|'negative'
}

type Props = {
    stats: Stat[]
}

const Stats: FC<Props> = ({ stats }): JSX.Element => {

    const remainderFields = useMemo(() => {
        const remainder = 4 - stats.length % 4;
        return remainder<4 ?remainder :0;
    }, [ stats.length ]);

    const itemWrapper = useCallback((stat: Stat|undefined, index: number, isPlaceHolder: boolean = false) => {
        return <>
            <tr>
              <td className={ classNames( 'text-sm font-medium leading-6 text-aqua w-60' )}>
                { stat?.name }
              </td>
              <td className={ classNames( 'text-sm font-bold leading-6 text-aqua' )}>
                { stat?.change }
                { stat?.value ?? (!isPlaceHolder ?<Skeleton type={ 'stat.body' }/> :'') }
              </td>
            </tr>
            </>
    }, []);

    return (
        <div className={ 'mx-4 sm:mx-10 lg:mx-1 lg:my-5 border-b border-gray-200' }>
            <div className=" md:mx-2 lg:mx-0 lg:my-5">
                <table className={ classNames( "w-full" ) }>
                  <tbody>
                    { stats.map((stat, i) => itemWrapper(stat, i)) }
                    { [ ...Array(remainderFields) ].map((_, i) => itemWrapper(undefined, i, true)) }
                  </tbody>
                </table>
            </div>
        </div>
    );
};
export default Stats;