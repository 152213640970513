import React, { FC, useMemo, useState } from 'react';
import ExampleGraph from '../../lib/samfe/components/Graph/ExampleGraph';
import Stats from '../../lib/samfe/components/Stats';
import ShowPage from '../../templates/pages/ShowPage';
import PackagePartForm from './PackagePartForm';
import { PackagePartModel } from './PackagePartTypes';
import PackagePartPackageTable from './pivot/PackagePartPackageTable';
import usePackagePart from './usePackagePart';


/**
 *
 * @constructor
 */
const PackagePartShow: FC = (): JSX.Element => {

    const [ currentPackagePart, setCurrentPackagePart ] = useState<PackagePartModel|undefined>(undefined);

    const packagePartName = useMemo(() => {
        const packageName = currentPackagePart?.name;
        if (!packageName) {
            return 'Onbekend verpakkingsonderdeel';
        }
        return packageName;
    }, [ currentPackagePart?.name ]);

    const title = useMemo(() => {
        let packageTitle = packagePartName;
        if (currentPackagePart?.short_name) {
            packageTitle = `${ packageTitle } (${ currentPackagePart.short_name })`;
        }
        return packageTitle;
    }, [ currentPackagePart?.short_name, packagePartName ]);

    return (
        <ShowPage
            title={ title }
            breadcrumb={ packagePartName }
            setCurrentModel={ setCurrentPackagePart }
            FormModal={ PackagePartForm }
            httpHook={ usePackagePart }
            relations={ [
                'packages',
                'packages.articles',
                'packages.parts',
                'packages.articles.package',
                'packages.articles.product'
            ] }
            tabs={ [
                {
                    name: 'Overzicht',
                    id: 'overview',
                    element: <>
                        <Stats stats={
                            [
                                {
                                    name: 'Type',
                                    value: currentPackagePart?.type
                                }
                            ]
                        }/>
                        <ExampleGraph/>
                    </>
                },
                {
                    name: 'Verpakkingen',
                    id: 'packages',
                    element: <>
                        {currentPackagePart?.id && <PackagePartPackageTable parentId={ currentPackagePart?.id }/>}
                    </>
                }
            ] }
        />
    );
};
export default PackagePartShow;