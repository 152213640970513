import React, { FC, useEffect, useState } from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import DynamicSearch from '../../../../lib/samfe/components/Form/Field/SelectSearch/DynamicSearch';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { sqlDateTimeToDate } from '../../../../lib/samfe/modules/Parse/Date';
import { LocationSearchResult } from '../../../location/ChargeLocationTypes';
import { getProductVersionDisplayNameByProduct } from '../../../product/ProductFunctions';
import { ProductModel } from '../../../product/ProductTypes';
import useProduct from '../../../product/useProduct';
import usePurchase from '../../usePurchase';
import { PurchaseRowDto, PurchaseRowModel } from '../purchaseRow/PurchaseRowTypes';
import usePurchaseRow from '../purchaseRow/usePurchaseRow';
import { BookInDto, BookInFields, BookInModel, BookInRelationBluePrint } from './BookInTypes';
import useBookIn from './useBookIn';


/** @info what is parent id in this context? */
const BookInForm: FC<ExtendFormModalProps<PurchaseRowDto>> = ({ parentId, id, open, setOpen, onSuccess }): JSX.Element => {

    const purchaseRowHook = usePurchaseRow(parentId);
    const purchaseHook = usePurchase();
    const productHook = useProduct();

    const [ currentPurchaseRow, setCurrentPurchaseRow ] = useState<PurchaseRowModel>();
    const [ locationSearchResults, setLocationSearchResults ] = useState<LocationSearchResult[]>([]);
    const [ availableProducts, setAvailableProducts ] = useState<ProductModel[]>([]);
    const [ currentProduct, setCurrentProduct ] = useState<ProductModel>();


    const shape = (): Shape<BookInDto> => ({
        article_id: Yup.number()
            .inputType('hidden')
            .controlType('input')
            .defaultValue(currentPurchaseRow?.article_id ?? 0),

        purchase_row_id: Yup.number()
            .inputType('hidden')
            .controlType('input')
            .defaultValue(id ?? 0),

        batchcode: Yup.string()
            .label('Charge')
            .required()
            .inputType('text')
            .controlType('input')
            .defaultValue(currentPurchaseRow?.charge?.batchcode ?? ''),

        version: Yup.string()
            .label('Productversie')
            .description(currentProduct ?`Bestelde productversie: ${ getProductVersionDisplayNameByProduct(currentProduct) }` :'')
            .inputType('text')
            .controlType('select')
            .options(availableProducts.map((product, i) => ({
                displayName: getProductVersionDisplayNameByProduct(product),
                value: product.version,
                selected: currentProduct ?currentProduct?.id == product.id :i == 0
            }))),

        locations: Yup.number()
            .hidden(true),

        amount: Yup.number()
            .label('Aantal ontvangen')
            .description(`Aantal besteld = ${ currentPurchaseRow?.quantity ?? 0 }`)
            .required()
            .inputType('number')
            .controlType('input')
            .defaultValue(currentPurchaseRow?.quantity ?? 0),

        price_per_amount: Yup.number()
            .label('Prijs per stuk')
            .description('Per 1000 voor bulk')
            .required()
            .inputType('number')
            .controlType('input')
            .defaultValue(parseFloat(`${ currentPurchaseRow?.price_per_amount ?? 0 }`)),

        expiration_date: Yup.string()
            .label('THT')
            .required()
            .inputType('date')
            .controlType('input'),

        order_date: Yup.string()
            .label('Besteldatum')
            .required()
            .hidden(true)
            .inputType('date')
            .controlType('input')
            .defaultValue(sqlDateTimeToDate(currentPurchaseRow?.purchase?.order_date)),


        charge_receive_date: Yup.string()
            .label('Inboekdatum')
            .required()
            .inputType('date')
            .controlType('input')
            .defaultValue(sqlDateTimeToDate()),

        performed_by: Yup.string()
            .label('Naam verwerker')
            .required()
            .inputType('text')
            .controlType('input'),

        approved_by: Yup.string()
            .label('Gecontroleerd door')
            .inputType('text')
            .controlType('input'),

        pack_slip_note: Yup.string()
            .label('Pakbon notitie')
            .controlType('textArea')
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<BookInDto>(shape());

    useEffect(() => {
        setShape(shape());
    }, [ availableProducts, currentPurchaseRow, availableProducts, currentProduct ]);

    const initializer = async() => {
        await purchaseRowHook.getItem(id, {
            with: [
                'charge',
                'purchase',
                'article.product',
                'product'
            ]
        }).then(async purchaseRow => {
            setCurrentPurchaseRow(purchaseRow);

            await productHook.getList({
                limit: 'all',
                filter: `number=${ purchaseRow?.product?.number }`,
                with: [ 'productVersionLogs' ]
            }).then(availableProducts => {
                setAvailableProducts(availableProducts);
                setCurrentProduct(availableProducts.find(product => product.id == purchaseRow?.product_id));
            });
        });

    };

    /**
     *
     */
    const { formikConfig, formFields } = useForm<BookInModel, BookInDto, BookInRelationBluePrint>({
        id,
        validationSchema,
        parentId,
        onSuccess: async(chargeId: number|undefined) => {

            // Mark associated row as booked in.
            await purchaseRowHook.update(id!, {
                status: 'booked'
            }).then(async() => {
                // Check if all rows of purchaseHook are booked in -> mark as booked.
                await purchaseHook.getItem(parentId, { with: [ 'purchaseRows' ] })
                    .then(async purchaseModel => {
                        let isBookedIn = true;
                        for (const purchaseRowModel of (purchaseModel?.purchaseRows ?? [])) {
                            if (purchaseRowModel?.status != 'booked') {
                                isBookedIn = false;
                                break;
                            }
                        }

                        if (isBookedIn) {
                            await purchaseHook.update(parentId!, {
                                status: 'booked'
                            });
                        }
                    });
            }).finally(() => {
                onSuccess?.(chargeId);
            });

        },
        morphPayload: (formikValues, dto) => {
            const payload = {
                ...dto
            };
            payload.locations = locationSearchResults;

            return { ...payload } as Partial<BookInFields>;
        },
        useHttpHook: useBookIn,
        skipInitialFetch: true,
        staticFormMethod: 'POST',
        initializer,
        initialized: useAsyncInit(initializer, open)
    });


    return <FormModal
        id={ id }
        parentId={ parentId }
        resource={ 'Inkooporder inboeken' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        operationName={ '' }
        htmlBeforeForm={ <DynamicSearch label={ 'Locaties' } config={
            {
                searchResource: 'search/locations',
                values: locationSearchResults,
                setValues: setLocationSearchResults,
                initialDisplay: undefined
            }
        }/> }
        htmlAfterForm={ <>
            <div className="text-sm my-4">
                <b>Procedure:</b><br/>
                Bij binnenkomst onderstaande stappen verrichten:<br/>
                (Melden afwijking bij Opmerkingen)<br/><br/>
                <ul className="list-disc list-inside">
                    <li>Verwijder verpakkingsfolie</li>
                    <li>Beschadigingen</li>
                    <li>Meld ontbrekende pakbon</li>
                    <li>Meld afwijkingen pakbon / ontvangen goederen</li>
                    <li>Seal controleren, 5 potten open en dicht draaien</li>
                    <li>Een pot apart leggen als referentie materiaal</li>
                    <li>Iedere doos voorzien van sticker</li>
                </ul>
                <br/>
                <b>Extra voor Zwik:</b>
                <ul className="list-disc list-inside">
                    <li>Product overstapelen op zwarte pallet</li>
                    <li>Controle dozen en plastic zakken zijn dicht</li>
                    <li>Analyse pot 2 potten van 200 stuks in passend formaat opgeslagen</li>
                </ul>
                <br/>
                Indien afwijkingen aanwezig zijn, melden bij Inkoop.
            </div>
        </> }
    />;
};

export default BookInForm;
