import React, { FC, useState } from 'react';
import TextArea from '../../../../../lib/samfe/components/Form/Generic/TextArea';
import { AttributeFieldProps } from './AttributeProductForm';


const AttributeTextAreaItem: FC<AttributeFieldProps> = ({ productId, item, onChange }) => {
    const [ currentValue, setCurrentValue ] = useState(item.attribute_value ?? '');


    const handleChange = (value: string) => {
        setCurrentValue(value);
        if (value === '') {
            return;
        }
        onChange({
            attribute_id: item.attribute_id,
            product_id: productId,
            attribute_value: value,
            order: item.order
        });
    };


    return (
        <div>
            <TextArea
                label={ '' }
                value={ currentValue }
                name={ item.attribute_name }
                onChange={ (newVal) => handleChange(newVal as string) }
                maxLength={ 512 }
                invalid={ currentValue == '' ?{
                    isInvalid: true,
                    message: currentValue == '' ?'Veld is verplicht!' :undefined
                } :undefined }
            />
        </div>
    );
};


export default AttributeTextAreaItem;
