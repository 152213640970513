import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { sqlDateTimeToDate } from '../../../../lib/samfe/modules/Parse/Date';

import { CustomerArticleModel, CustomerArticleRelationsBluePrint } from './CustomerArticleTypes';
import useCustomerArticle from './useCustomerArticle';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const CustomerArticleTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useCustomerArticle( parentId );

    const rows = useMemo((): TableColumns<CustomerArticleModel, CustomerArticleRelationsBluePrint>[] => [
					{
							header: {
									children: 'Product',
									sortCol: 'product_name'
							},
							column: (item) => ({
									children: item.product_name
							}),
							type: 'id'
					},
					{
							header: {
									children: 'Artikel',
							},
							column: (item) => ({
									children: item.article_number
							}),
							type: 'id'
					},
					{
							header: {
									children: 'Gem. prijs p/s',
							},
							column: (item) => ({
									children: item.avg_price
							}),
							type: 'numeric'
					},
					{
							header: {
									children: 'Aantal (besteld)',
							},
							column: (item) => ({
									children: item.quantity
							}),
							type: 'numeric'
					},
					{
							header: {
									children: 'Klantuitgave',
							},
							column: (item) => ({
									children: '€ ' + item.total_spent
							}),
							type: 'numeric'
					},
					{
							header: {
									children: 'Actuele prijs p/s',
							},
							column: (item) => ({
									children:  '€ ' + item.last_price
							}),
							type: 'numeric'
					},
					{
						header: {
								children: 'Laatste besteldatum',
						},
						column: (item) => ({
								children: sqlDateTimeToDate( item.last_ordered_at )
						}),
						type: 'id'
				},
			], []);



			return <>
					<Table
							id={ 'articles' }
							rows={ rows }
							rowActions={ {
									linkTo: (item) => `/sales/${ item.id }`
							} }
							http={ {
									hook: httpHook,
									sortCol: 'product_name',
									sortDir: 'ASC',
									searchCols: [ 'product_name' ]
							} }
					/>
			</>;

};

export default CustomerArticleTable;