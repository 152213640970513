import React, { FC, useEffect, useMemo, useState } from 'react';
import { Option } from '../../../../../deprecated/components/Input/InputTypes';
import Select from '../../../../../deprecated/components/Input/Select';
import { AttributeFieldProps } from './AttributeProductForm';


const AttributeSelectItem: FC<AttributeFieldProps> = ({item, productId, onChange}) => {

    const [ currentValue, setCurrentValue ] = useState(item.attribute_product_options?.[0]?.id);


    const attributeOptions = useMemo(() => {
        return (item.attribute_options ?? []).sort((a, b) => a.name.localeCompare(b.name));
    }, [ item.attribute_options ]);


    const options = useMemo(() => {
        return attributeOptions.map(ao => ({
            displayName: ao.name,
            value: ao.id,
            selected: currentValue == ao.id
        }) as Option);
    }, [ attributeOptions, currentValue ]);


    const handleChange = (optionId: number) => {
        setCurrentValue(optionId)
        if (!optionId) {
            return;
        }

        onChange({
            attribute_id: item.attribute_id,
            product_id: productId,
            attribute_option_ids: [optionId],
            order: item.order
        });
    }

    return (
        <div>
            <Select
                value={ currentValue }
                overflow={ true }
                name={ item.attribute_name }
                required={ true }
                invalid={ !currentValue }
                options={ options }
                onChange={ (_, optionId) => handleChange(optionId as unknown as number) }
            />
        </div>
    );
};

export default AttributeSelectItem;
