import React, { FC, useMemo } from 'react';
import CompoundForm from './CompoundForm';
import { CompoundModel, CompoundRelationsBluePrint } from './CompoundTypes';
import useCompound from './useCompound';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";


type Props = Required<ExtendTableProps>

const CompoundTable: FC<Props> = () => {

    const httpHook = useCompound();

    const rows = useMemo((): TableColumns<CompoundModel, CompoundRelationsBluePrint>[] => [
        {
            header: {
                children: 'Grondstofverbinding',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Aantal gelinkte grondstoffen',
                sortCount: 'elements'
            },
            column: (item) => ({
                children: item.elements_count
            }),
            type: 'numeric'
        },
    ], []);

    return (
        <Table
            id={ 'compounds' }
            rows={ rows }
            rowActions={{
                linkTo: (item) => `${item.id}`
            }}
            http={ {
                hook: httpHook,
                searchCols: ['name'],
                sortCol: 'name',
                sortDir: 'ASC',
                withCount: ['elements']
            } }
            forms={ {
                edit: {
                    Form: CompoundForm,
                    id: (record) => record.id,
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => record.name ,
                    resourceName: () => 'Grondstof verbinding'
                },
            } }
        />
    );
};

export default CompoundTable;
