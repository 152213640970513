import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { dispatchHttpEvent } from '../../../../events/Http.event';
import Checkbox from '../../../../lib/samfe/components/Form/Generic/Checkbox';
import ArchiveModal from '../../../../lib/samfe/components/Modal/ArchiveModal';
import { UseHttp } from '../../../../lib/samfe/modules/Http/useHttp';
import { ElementRiskCodeModel, ElementRiskCodeRelationsBluePrint } from '../elementRiskCode/ElementRiskCodeTypes';
import useElementRiskCode from '../elementRiskCode/useElementRiskCode';
import { ElementRiskCodeItem, ElementRiskModel, ElementRiskRelationsBluePrint } from './ElementRiskTypes';
import useElementRisk from './useElementRisk';


type ElementRiskCodeCheckboxProps = {
    elementId: number
    elementRiskId?: number
    riskCodeItem: ElementRiskCodeItem
}

const ElementRiskCodeCheckbox: FC<ElementRiskCodeCheckboxProps> = ({
    elementId,
    elementRiskId,
    riskCodeItem
}) => {

    const elementRiskCodeHook = useElementRiskCode(elementId);
    const [ currentRiskCodeItem, setCurrentRiskCodeItem ] = useState<ElementRiskCodeItem>(riskCodeItem);
    const [ openRiskCodeArchiveModal, setOpenRiskCodeArchiveModal ] = useState(false);

    useEffect(() => {
        setCurrentRiskCodeItem(riskCodeItem)
    }, [riskCodeItem]);

    const riskCodeName = useMemo(() => {
        return currentRiskCodeItem.name;
    }, [ currentRiskCodeItem.name ]);

    const riskRiskCodeId = useMemo(() => {
        return currentRiskCodeItem.risk_risk_code_id;
    }, [ currentRiskCodeItem.risk_risk_code_id ]);

    const elementRiskCodeId = useMemo(() => {
        return currentRiskCodeItem.element_risk_code_id;
    }, [ currentRiskCodeItem.element_risk_code_id ]);

    const hasElementRiskCodeId = useMemo(() => {
        return elementRiskCodeId != null;
    }, [ elementRiskCodeId ]);


    const handleCreate = async(elementRiskId: number) => {
        await elementRiskCodeHook
            .create({
                risk_risk_code_id: riskRiskCodeId,
                element_risk_id: elementRiskId
            })
            .then((res) => {
                setCurrentRiskCodeItem({
                    ...currentRiskCodeItem,
                    element_risk_code_id: res.id
                })
            });
    };

    const handleChange = async(riskCodeActive: boolean) => {
        if (!elementRiskId) {
            console.warn(`Element risk id is missing for risk code ${ riskCodeName }`);
            return;
        }

        if (riskCodeActive && !hasElementRiskCodeId) {
            await handleCreate(elementRiskId);
            return;
        }
        setOpenRiskCodeArchiveModal(true);
    };

    return <>
        <div className={'block'}>
            <div className={ 'inline-flex gap-x-2' }>
                <div>
                    <Checkbox
                        name={ '' }
                        checked={ hasElementRiskCodeId }
                        onChange={ handleChange }
                        className={'!m-0'}
                    />
                </div>
                <div>
                    <p className={'text-xs mt-[0.225rem]'}>{ riskCodeName }</p>
                </div>
            </div>
        </div>

        { openRiskCodeArchiveModal && elementRiskCodeId && <ArchiveModal
            open={ openRiskCodeArchiveModal }
            setOpen={ setOpenRiskCodeArchiveModal }
            useHttp={ elementRiskCodeHook as UseHttp<ElementRiskCodeModel, Partial<ElementRiskCodeModel>, ElementRiskCodeRelationsBluePrint> }
            resourceName={ 'Risicocode' }
            itemName={ riskCodeName }
            id={ elementRiskCodeId }
            onSuccess={ () => {
                setCurrentRiskCodeItem({
                    ...currentRiskCodeItem,
                    element_risk_code_id: null
                })
            } }
        /> }
    </>;
};


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ElementRiskTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useElementRisk(parentId);
    const [ currentElementRisk, setCurrentElementRisk ] = useState<ElementRiskModel>();
    const [ openRiskArchiveModal, setOpenRiskArchiveModal ] = useState(false);


    const rows = useMemo((): TableColumns<ElementRiskModel, ElementRiskRelationsBluePrint>[] => [
        {
            header: {
                children: 'Naam',
                sortCol: 'risk_name'
            },
            column: (item) => ({
                children: <>
                    <Link to={ `/settings/risks/${ item.risk_id }` } className={ 'underline decoration-aqua text-aqua underline-offset-2 decoration-[0.03125rem]' }>
                        { item?.risk_name }
                    </Link>

                    <div className={'mt-2'}>
                        { item.id && (item.risk_codes??[])
                            .map((riskCodeItem, i) => <ElementRiskCodeCheckbox
                                key={i}
                                elementId={ parentId }
                                riskCodeItem={ riskCodeItem }
                                elementRiskId={ item.id }
                            />) }
                    </div>

                    { item.id && item.risk_codes?.length === 0 && <small className={ 'block text-gray-500' }>
                        Geen risicocodes beschikbaar.
                    </small> }
                </>
            }),
            type: 'element'
        }
    ], [ parentId ]);

    return <>
        <Table
            id={ 'risks' }
            rows={ rows }
            http={ {
                // @ts-ignore
                hook: httpHook,
                //@ts-ignore
                searchCols: [ 'name' ],
                sortCol: 'risk_name',
                sortDir: 'ASC',
                filters: [
                    {
                        displayName: 'Actief',
                        column: 'id',
                        operator: '!=',
                        value: 'null'
                    },
                    {
                        displayName: 'Inactief',
                        column: 'id',
                        operator: '=',
                        value: 'null'
                    }
                ],
                filterConfig: {
                    hideArchived: true
                },
                disableDeArchiving: true
            } }
            callbacks={ [
                {
                    title: 'Activeren',
                    hideForRow: (item) => item.id != undefined,
                    onClick: async(item) => {
                        httpHook.create({
                                risk_id: item.risk_id!,
                                element_id: parentId
                            })
                            .then(dispatchHttpEvent);
                    }
                },
                {
                    title: 'Deactiveren',
                    variation: 'destructive',
                    hideForRow: (item) => !item.id,
                    onClick: (item) => {
                        setCurrentElementRisk(item)
                        setOpenRiskArchiveModal(true)
                    }
                }
            ] }
            styling={ {
                variation: 'buttons'
            } }
        />

        { openRiskArchiveModal && currentElementRisk && <ArchiveModal
            open={ openRiskArchiveModal }
            setOpen={ setOpenRiskArchiveModal }
            useHttp={ httpHook }
            resourceName={ 'Risico' }
            itemName={ currentElementRisk.risk_name }
            id={ currentElementRisk.id }
            onSuccess={ () => {
                setCurrentElementRisk(undefined)
                dispatchHttpEvent()
            } }
            onCancel={ () => setCurrentElementRisk(undefined) }
        /> }
    </>;
};

export default ElementRiskTable;