import React, {useEffect, useState} from "react";
import CrudManager from "../../../../lib/samfe/modules/CrudManager";
import Input from "../../../../deprecated/components/Input/Input";
import {PurchaseModel} from "../../PurchaseTypes";
import Switch from "../../../../deprecated/components/Input/Switch";
import {ActionButton, DeleteButton, SaveButton} from "../../../../lib/samfe/components/Button";
import useLoadingScreen from "../../../../lib/samfe/components/LoadingScreen/useLoadingScreen";
import {classNames} from "../../../../lib/samfe/modules/Parse/String";
import {jsDateToSqlDate} from "../../../../lib/samfe/modules/Parse/Date";
import useAxios from "../../../../lib/samfe/modules/Http/useAxios";
import useCurrentUser from "../../../user/useCurrentUser";

type CMItem = {
    id: number
    email_address: string
}
type MailDto = {
    email_addresses: string[]
}

type Props = {
    purchase: PurchaseModel
    onSuccess?: () => Promise<void>|void
}

const SendPurchaseInnerForm: React.FC<Props> = ({
    purchase,
    onSuccess
}): JSX.Element => {

    const [hasError, setHasError] = useState(false);
    const {user: {profile}} = useCurrentUser();
    const {post, put} = useAxios();
    const [producerActive, setProducerActive] = useState(false);
    const [ altProducerEmailActive, setAltProducerEmailActive ] = useState(false);
    const [ownEmailActive, setOwnEmailActive] = useState(false);
    const initialItem = (id?: number): CMItem => ({id: id ?? 0, email_address: ''});
    const [cm] = useState(new CrudManager<CMItem>('id', [], [initialItem()]));
    const {setLoadingScreenProps} = useLoadingScreen();

    const [refresh, setRefresh] = useState(false);
    useEffect(() => {
        if (refresh) {
            setRefresh(false)
        }
    }, [refresh]);


    const submit = async () => {

        const payload: MailDto = {
            email_addresses: []
        };

        if (producerActive) {
            payload.email_addresses.push(purchase.producer?.email!);
        }

        if (altProducerEmailActive) {
            payload.email_addresses.push(purchase.producer?.email_alt!);
        }

        if (ownEmailActive) {
            payload.email_addresses.push(profile!.email);
        }

        cm.getCurrentItems().forEach((item) => {
            payload.email_addresses.push(item.email_address)
        })

        //if (payload.email_addresses.length === 0) {
        //    setHasError(true);
        //    return;
        //}
        //setHasError(false);

        setLoadingScreenProps({show: true})

        let hasError = false;
        await post(`purchases/${purchase.id}/mail`, payload).then( async () => {
            // Update purchase status as sent
            await put(`purchases/${purchase.id}`, {
                status: 'sent',
                order_date: jsDateToSqlDate()
            }).then( async () => {
                // Update purchase rows status to sent
                for (const purchaseRow of purchase?.purchaseRows ?? []) {
                    if (purchaseRow.status !== 'open') {
                        continue;
                    }

                    await put(`purchases/${purchase.id}/rows/${purchaseRow.id}`, {status: 'sent'}).catch(() => {
                        hasError = true
                    })
                }
            }).catch(() => {
                hasError = true;
            })
        }).catch(() => {
            hasError = true;
        })

        setLoadingScreenProps({show: false})

        if (!hasError) {
            await onSuccess?.();
        }
    }




    return <>
        {/*<Toaster show={hasError} title={'Verzenden mislukt'} setShow={setHasError} />*/}
        <div className={'!text-left'}>

            <div className="mt-2 mb-4">
                <h3 className={'text-lg font-medium text-graphite'}>Selecteer email adressen</h3>
                <span className={classNames(hasError ? 'block': 'hidden','font-medium text-fire')}>Selecteer minimaal 1 email adres</span>
                <Switch id={'producerMail'} label={`${purchase.producer?.email} (leverancier)`} active={producerActive} onChange={() => setProducerActive(!producerActive)} name={'producer'} value={purchase.producer?.email} />
                { purchase.producer?.email_alt && <Switch id={ 'producerAltMail' } label={ `${ purchase.producer?.email_alt } (leverancier)` } active={ altProducerEmailActive } onChange={ () => setAltProducerEmailActive(!altProducerEmailActive) } name={ 'producer' } value={ purchase.producer?.email_alt }/> }
                <Switch id={'ownEmail'} label={`${profile!.email} (jouw email adres)`} active={ownEmailActive} onChange={() => setOwnEmailActive(!ownEmailActive)} name={'self'} value={purchase.producer?.email} />
            </div>

            {cm.getCurrentItems().map(item =>
                <div key={item.id} className={'grid grid-cols-6 mb-4'}>
                    <div className={'col-span-5'}>
                        <Input
                            name={'email'}
                            value={item.email_address}
                            type={'email'}
                            onChange={(k, v) => {
                                cm.updateItem({...item, email_address: v})
                                // setRefresh(true)
                            }}
                        />
                    </div>
                    <div className={'col-span-1 text-right'}>
                        <DeleteButton small={true} className={'!p-1.5'} onClick={() => {
                            cm.deleteItem(item.id)
                            setRefresh(true)
                        }}  />
                    </div>
                </div>
            )}

            <ActionButton icon={''} text={'Email toevoegen'} className={'w-44'} onClick={ () => {
                const currentItems = cm.getCurrentItems();
                const newId = cm.getCurrentItems().length
                const clone = [...currentItems.map((ci, i) => ({...ci, id: i})), initialItem(newId)];
                cm.init(clone, clone)
                setRefresh(true)
            }} />

            <div className={'mt-4 text-graphite text-sm'}>
                <p>Deze email wordt verzonden namens administratie@triplepharma.nl</p>
                <p>Een kopie van deze bestelling wordt ook naar dit email adres gestuurd.</p>
            </div>


            <hr className={'my-8'}/>
            <SaveButton className={'float-right'} onClick={() => submit().then()}>Verzenden</SaveButton>
        </div>
    </>
}
export default SendPurchaseInnerForm;