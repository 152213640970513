import React, { FC, useMemo } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { PurchaseModel, PurchaseRelationsBluePrint, PurchaseStatusTranslation } from '../../purchase/PurchaseTypes';
import usePurchase from '../../purchase/usePurchase';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ProducerPurchaseRowTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = usePurchase();


    const rows = useMemo((): TableColumns<PurchaseModel, PurchaseRelationsBluePrint>[] => [
        {
            header: {
                children: 'Inkooporder',
                sortCol: 'id'
            },
            column: (item) => ({
                children: item.id
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Datum',
                sortCol: 'order_date'
            },
            column: (item) => ({
                children: item.order_date
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Aantal regels'
            },
            column: (item) => ({
                children: item.purchaseRows_count
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Status',
                sortCol: 'status'
            },
            column: (item) => ({
                children: PurchaseStatusTranslation(item.status)
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'purchases' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/purchases/${ item.id }`
            } }
            http={ {
                hook: httpHook,
                withCount: [ 'purchaseRows' ],
                sortCol: 'id',
                sortDir: 'DESC',
                filter: {
                    column: 'producer_id',
                    operator: '=',
                    value: parentId
                }
            } }
        />
    </>;
};
export default ProducerPurchaseRowTable;