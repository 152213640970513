import React, { FC, useMemo } from 'react';
import PrinterForm from './PrinterForm';
import { PrinterModel, PrinterRelationsBluePrint } from './PrinterTypes';
import usePrinter from './usePrinter';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";


type Props = Required<ExtendTableProps>

const PrinterTable: FC<Props> = () => {

    const httpHook = usePrinter();

    const rows = useMemo((): TableColumns<PrinterModel, PrinterRelationsBluePrint>[] => [
        {
            header: {
                children: 'Printer',
                sortCol: 'display_name'
            },
            column: (item) => ({
                children: item.display_name
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Interne Naam',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text',
        },
    ], []);

    return (<>
        <Table
            id={ 'printers' }
            rows={ rows }
            rowActions={{
                linkTo: (item) => `${item.id}`
            }}
            http={ {
                hook: httpHook,
                searchCols: ['display_name', 'name'],
                sortCol: 'display_name',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: PrinterForm,
                    id: (record) => record.id,
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => record.display_name,
                    resourceName: () => 'Printer'
                },
            } }
        />
    </>
    );
};

export default PrinterTable;