import React, { CSSProperties, Fragment, ReactNode } from 'react';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import ErrorMessage from '../../Caption/ErrorMessage';
import Label from "../../Caption/Label";
import Skeleton from "../../../Skeleton/Skeleton";
import {classNames} from "../../../../modules/Parse/String";
import { InvalidField } from '../GenericFieldTypes';
import {SelectOption, useSelect} from "./useSelect";


type Props<TType> = {
    style?: CSSProperties,
    label?: ReactNode;
    name: string;
    required?: boolean
    options: SelectOption[],
    multiSelect?: boolean,
    onChange?: (option: TType) => void,
    className?: string,
    maxWidth?: string
    buttonClassName?: string,
    invalid?: InvalidField,
    disabled?: boolean
}

const Select = <TType extends SelectOption|SelectOption[] = SelectOption,>({
    label,
    name,
    required,
    options,
    multiSelect,
    onChange,
    className,
    maxWidth,
    buttonClassName,
    invalid,
    disabled,
    style
}: Props<TType>): JSX.Element => {

    const {selected, setSelected} = useSelect(name, options, multiSelect);


    /**
     *
     */
    const displayValue = () => multiSelect !== true
        ? (selected as SelectOption)?.displayName
        : <>{(selected as SelectOption[])?.map((option, i, arr) =>
            <div key={i} className={'px-2 py-1 inline-block rounded bg-blue-700 text-white text-sm my-0.5 mr-1'}>
                {option?.displayName}
                {arr.length > 1 && <button
                    className="ml-2 text-white align-middle focus:outline-none focus:ring-2 focus:ring-sky focus-visible:ring-offset-2 hover:cursor-pointer"
                    onClick={() => {
                        const newArr = arr.filter((item, j) => i !== j || option.value !== item.value);
                        setSelected(newArr.filter((v, i, a) => a.indexOf(v) === i))
                    }}
                >
                    <span className="material-icons text-sm relative top-0.5">close</span>
                </button>}
            </div>
        ) ?? []}</>


    const handleChange = (value: TType) => {
        setSelected(value);
        if (onChange) {
            onChange(value);
        }
    }


    return <>
        <Listbox multiple={multiSelect === true} value={selected} onChange={handleChange}>
            {({ open}) => (<div style={style} className={maxWidth + ' mt-2'}>

                { label !== undefined && <Listbox.Label>
                    <Label htmlFor={ name } invalid={ invalid?.isInvalid }>{ label }{ required && '*' }</Label>
                </Listbox.Label> }

                <div className="relative mt-1">

                    <Listbox.Button disabled={disabled} className={classNames(
                        "relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6",
                        invalid?.isInvalid ?'ring-red-700' :'ring-gray-300',
                        disabled && 'hover:cursor-not-allowed opacity-60',
                        buttonClassName
                    )}>
                        <span className="block truncate">{displayValue()}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"><ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /></span>
                    </Listbox.Button>

                    {/* Dropdown */}
                    <Transition show={open && !disabled} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Listbox.Options className={classNames("z-[11] absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm", className)}>

                            {options.map((option) => <Listbox.Option
                                key={option.value}
                                value={option}
                                className={({ active, selected }) => classNames(selected && 'bg-blue-700', active && '!bg-blue-600', (active || selected) ? 'text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9 border-b border-b-white hover:cursor-pointer')}
                            >
                                {({ active, selected }) => (<>
                                    <span className={classNames((selected || active) ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        {option.displayName}
                                    </span>
                                    {selected && <span className={classNames( active || selected ? 'text-white' : 'text-blue-700', 'absolute inset-y-0 right-0 flex items-center pr-4')}><CheckIcon className="h-5 w-5" aria-hidden="true" /></span>}
                                </>)}
                            </Listbox.Option>)}

                        </Listbox.Options>
                    </Transition>
                    { invalid?.isInvalid && invalid?.message !== undefined && <ErrorMessage message={ invalid?.message }/> }
                </div>
            </div>)}
        </Listbox>
    </>
}
export default Select;
