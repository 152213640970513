import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { batchCodeLabel, expirationDateLabel } from '../../../charge/ChargeFunctions';
import { ChargeModel, ChargeRelationsBluePrint } from '../../../charge/ChargeTypes';
import useProducerCharge from './useProducerCharges';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const ProducerChargeTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useProducerCharge(parentId);


    const rows = useMemo((): TableColumns<ChargeModel, ChargeRelationsBluePrint>[] => [
        {
            header: {
                children: 'Artikel'
            },
            column: (item) => ({
                children: item.article?.number
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Product'
            },
            column: (item) => ({
                children: item.product?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Charge',
                sortCol: 'batchcode'
            },
            column: (item) => ({
                children: batchCodeLabel(item)
            }),
            type: 'element'
        },
        {
            header: {
                children: 'THT',
                sortCol: 'expiration_date'
            },
            column: (item) => ({
                children: expirationDateLabel(item.expiration_date)
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Aantal'
            },
            column: (item) => ({
                children: item.purchaseRow?.booked_quantity
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Ontvangstdatum'
            },
            column: (item) => ({
                children: item.purchaseRow?.received_at
            }),
            type: 'date'
        }

    ], []);

    return <>
        <Table
            id={ 'charges' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/charges/${ item.id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: [ 'batchcode' ],
                with: [ 'purchaseRow', 'article', 'product' ],
                sortCol: 'expiration_date',
                sortDir: 'DESC',
                filters: []
            } }
        />
    </>;
};
export default ProducerChargeTable;