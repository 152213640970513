import React, { FC, useMemo, useRef } from 'react';
import { SaveButton } from '../../../../components/button';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import Disclosure from '../../../../lib/samfe/components/Disclosure/Disclosure';
import Switch from '../../../../lib/samfe/components/Form/Generic/Switch';
import TextArea from '../../../../lib/samfe/components/Form/Generic/TextArea';
import useAsyncMemo from '../../../../lib/samfe/hooks/useAsyncMemo';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import useProduct from '../../useProduct';
import ProductRiskForm from './ProductRiskForm';
import { GetGradationName, ProductRiskItem } from './ProductRiskTypes';
import useProductRiskForm from './useProductRiskForm';
import useProductRiskTable from './useProductRiskTable';


const RiskSubstantiationInlineForm: FC<{ productId: number }> = ({ productId }) => {

    const riskSubstantiationRef = useRef<HTMLTextAreaElement>(null);
    const productHook = useProduct();

    const riskSubstantiation = useAsyncMemo(async() => {
        return await productHook.getItem(productId, {
            select: [ 'risk_substantiation' ]
        }).then(res => res?.risk_substantiation);
    }, [ productId ]);

    return (
        <>
            <TextArea
                className={ '-mt-2' }
                ref={ riskSubstantiationRef }
                label={ '' }
                name={ 'risk_substantiation' }
                value={ riskSubstantiation }
            />
            <div className={ 'w-full flex justify-end pt-2 mb-4' }>
                <SaveButton
                    className={ '' }
                    disabled={ riskSubstantiationRef.current == null }
                    onClick={ async() => {
                        await productHook.update(productId, {
                            risk_substantiation: riskSubstantiationRef.current!.value
                        });
                    } }/>
            </div>
        </>
    );
};


type Props = ExtendTableProps

/**
 * Parent id = product id
 */
const ProductRiskTable: FC<Props> = ({ parentId }): JSX.Element => {

    const httpHook = useProductRiskTable(parentId);
    const productRiskResource = useProductRiskForm(parentId);


    const rows = useMemo((): TableColumns<ProductRiskItem, ''>[] => [
        {
            header: {
                children: 'Risico'
            },
            // @feature links to risk/elements
            column: (item) => ({
                children: <div className={ 'text-graphite' }>
                    <strong className={ classNames(!item.id && 'text-fire') }>{ item.name }</strong>
                    <ul>
                        { item.elements.map(element => <li key={ element.id }>
                                <span className={ classNames(!element.is_reviewed && 'text-fire') }>
                                    { element.name } { element.compound_name ?? element.excipient_name ?? '' }
                                    <small>&nbsp;({ element.composition_amount }{ element.composition_unit })</small>
                                </span>
                            <ul>
                                { element.risk_codes.map(riskCode => <li key={ riskCode.element_risk_code_id }>
                                    <small>{ riskCode.name }</small>
                                </li>) }
                            </ul>
                        </li>) }
                    </ul>
                </div>
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Risicogradatie'
            },
            column: (item) => ({
                children: <span className={ classNames(!item.gradation && 'text-fire') }>
                        { GetGradationName(item.gradation) }
                    </span>
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Analysefrequentie'
            },
            column: (item) => ({
                children: <span className={ classNames(item.frequency === null && 'text-fire') }>
                        { item.frequency ?? '-' }
                    </span>
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Charge blokkeren (analyse)'
            },
            column: (item) => ({
                children: <>
                    { item.id != null && item.product_id != null ?<Switch
                        name={ `${ item.name }-${ item.id }` }
                        checked={ item?.blocked_for_assessment }
                        onChange={ async(active) => {
                            // @todo refresh data on click
                            await productRiskResource.update(item.product_id!, item.id, {
                                blocked_for_assessment: active
                            });
                        } }
                    /> :<span className={ 'text-fire' }>Risico moet eerst behandeld worden</span> }
                </>
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Blokkeernotitie'
            },
            column: (item) => ({
                children: <>
                    { item.blocked_for_assessment == true && <>
                        { item.blocked_comment ?item.blocked_comment :<span className={ 'text-gray-500' }>Voeg een notitie toe via &quot;Aanpassen&quot;.</span> }
                    </> }
                </>
            }),
            type: 'element'
        }

    ], [ productRiskResource ]);


    return <>
        <Table
            id={ 'product-risks' }
            rows={ rows }
            http={ {
                hook: httpHook,
                showAll: true
            } }
            forms={ {
                edit: {
                    Form: ProductRiskForm,
                    parentId: () => parentId, // product id
                    id: (record) => record.risk_id
                }
            } }
            htmlBefore={ <>
                { parentId && <Disclosure title={ 'Risiconotities' } variation={ 'info' }>
                    <RiskSubstantiationInlineForm productId={ parentId }/>
                </Disclosure> }
            </> }
        />
    </>;
};

export default ProductRiskTable;