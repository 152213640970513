import React, { FC, useState } from 'react';
import { useForm } from '../../../../lib/samfe/components/Form';
import useAsyncInit from '../../../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../../../lib/samfe/components/Form/Effects/useSchema';
import Yup from '../../../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../../../lib/samfe/components/Modal/FormModal/FormModal';
import { filterIds } from '../../../../lib/samfe/modules/Parse/Url';
import { modelNotArchived } from '../../../../lib/samfe/types/ModelTypes';
import IngredientForm from '../../../ingredient/IngredientForm';
import useIngredient from '../../../ingredient/useIngredient';
import useProduct from '../../useProduct';
import { ProductIngredientDto, ProductIngredientModel, ProductIngredientRelationsBluePrint } from './ProductIngredientTypes';
import useProductIngredient from './useProductIngredient';


const ProductIngredientForm: FC<ExtendFormModalProps<ProductIngredientDto>> = ({
    id,
    open,
    setOpen,
    onSuccess,
    parentId
}): JSX.Element => {

    const product = useProduct();
    const productIngredient = useProductIngredient(parentId);
    const [initialPI, setInitialPI] = useState<ProductIngredientModel|undefined>(undefined);

    const currentElement = () => id ?<span className={ 'text-graphite font-medium' }>{ initialPI?.ingredient?.name }</span> :<></>;


    /**
     *
     */
    const shape = (occupiedIngredientIds: number[] = []): Shape<ProductIngredientDto> => ({

        ingredient_id: Yup.number()
            .label('Ingredient')
            .required()
            .defaultValue(id)
            .hidden(id !== undefined)
            .controlType('selectSearch')
            .selectSearchConfig({
                expectsInitialModel: false,
                useHook: useIngredient,
                searchOptions: {
                    searchCols: [ 'name' ],
                    valueCol: 'id',
                    limit: 'all',
                    // @fixme monkey patch for limit all
                    filter: filterIds({ ids: occupiedIngredientIds, exclude: true }) ?? 'archived=0',
                    displayName: model => `${ model.name }`,
                    FormModal: IngredientForm
                }
            }),

        product_id: Yup.number()
            .label('Leverancier')
            .hidden(true)
            .controlType('input')
            .inputType('text'),

        order: Yup.number()
            .hidden(true)
            .label('Positie')
            .controlType('input')
            .inputType('number')
            .steps(1)
            .defaultValue(initialPI?.order ?? 0)
    });

    /**
     *
     */
    const { validationSchema, setShape } = useSchema<ProductIngredientDto>(shape());

    // @todo reset states on re init
    const initializer = async () => {
        await productIngredient.getItem(id, {with: ['ingredient']}).then(productIngredient => {
            setInitialPI(productIngredient)
        })

        const occupiedIngredientIds: number[] = await product.getItem(parentId, {with: ['ingredientProducts']})
            .then(product => (product?.ingredientProducts??[])
                .filter(pi => modelNotArchived(pi.archived))
                .map(pi => pi.ingredient_id!)
            );

        setShape(shape(occupiedIngredientIds));
    };

    const { formikConfig, formFields } = useForm<ProductIngredientModel, ProductIngredientDto, ProductIngredientRelationsBluePrint>({
        id,
        parentId,
        parentKey: 'product_id',
        validationSchema,
        initializer,
        initialized: useAsyncInit(initializer, open),
        useHttpHook: useProductIngredient,
        onSuccess: onSuccess
    });

    return <FormModal
        id={ id }
        parentId={ parentId }
        resource={ 'Product ingredient' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
        htmlBeforeForm={ currentElement() }
    />;
};

export default ProductIngredientForm;
