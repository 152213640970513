import React, { FC, useState } from 'react';
import useId from '../../lib/samfe/modules/Router/useId';
import ShowPage from '../../templates/pages/ShowPage';
import RiskRiskCodeTable from './pivot/riskCode/RiskRiskCodeTable';
import RiskElementTable from './pivot/riskElement/RiskElementTable';
import RiskForm from './RiskForm';
import { RiskModel } from './RiskTypes';
import useRisk from './useRisk';


const RiskShow: FC = (): JSX.Element => {

    const [ currentRisk, setCurrentRisk ] = useState<RiskModel|undefined>(undefined);
    const id = useId();

    return (
        <ShowPage
            title={ currentRisk?.name }
            breadcrumb={ currentRisk?.name }
            setCurrentModel={ setCurrentRisk }
            FormModal={ RiskForm }
            httpHook={ useRisk }
            tabs={ [
                {
                    name: 'Grondstoffen',
                    id: 'overview',
                    element: <RiskElementTable parentId={ id }/>
                },
                {
                    name: 'Risicocodes',
                    id: 'risk-codes',
                    element: <RiskRiskCodeTable parentId={ id }/>
                }
            ] }
        />
    );
};
export default RiskShow;