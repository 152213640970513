import React, { FC, useState } from 'react';
import Input from '../../../../../deprecated/components/Input/Input';
import { AttributeFieldProps } from './AttributeProductForm';


const AttributeInputItem: FC<AttributeFieldProps> = ({productId, item, onChange}) => {
    const [ currentValue, setCurrentValue ] = useState(item.attribute_value ?? '');


    const handleChange = (value: string) => {
        setCurrentValue(value)
        if (value === '') {
            return;
        }
        onChange({
            attribute_id: item.attribute_id,
            product_id: productId,
            attribute_value: value,
            order: item.order
        });
    }


    return (
        <div>
            <Input
                type={ item.attribute_type as 'text'|'number' }
                value={ item.attribute_value ?? '' }
                name={ item.attribute_name }
                onChange={ (_, newVal) => handleChange(newVal)}
                required={ true }
                invalid={ currentValue === '' }
                max={512}
            />
        </div>
    );
};

export default AttributeInputItem;
