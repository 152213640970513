import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { DefaultTableFilter } from '../../components/table/types/TableFilter';
import { TableFormFields } from '../../components/table/types/TableForm';
import { TableColumns } from '../../components/table/types/TableRow';
import { API_URL } from '../../config/config';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import { FileType } from '../document/DocumentTypes';
import useFileHandler from '../document/useFileHandler';
import { getProductTypeUnit } from '../productType/ProductTypeFunctions';
import { ProductTypeModel } from '../productType/ProductTypeTypes';
import useProductType from '../productType/useProductType';
import ProductForm from './ProductForm';
import { ProductModel, ProductRelationsBluePrint } from './ProductTypes';
import useProduct from './useProduct';


type Props = ExtendTableProps&{
    filter?: DefaultTableFilter<ProductModel>,
    withRelations?: ProductRelationsBluePrint[],
    hideTypeColumn?: boolean,
    extraColumns?: TableColumns<ProductModel, ProductRelationsBluePrint>[],
    hideEdit?: boolean,
    hideArchive?: boolean
}

const ProductTable: FC<Props> = ({
    filter,
    withRelations = [],
    hideTypeColumn = false,
    extraColumns = [],
    hideEdit = false,
    hideArchive = false
}) => {

    const httpHook = useProduct();
    const productType = useProductType();
    const { downloadFileByUrl } = useFileHandler();

    const productTypes: ProductTypeModel[] = useAsyncMemo(async() => {
        if (hideTypeColumn) {
            return []
        }
        return await productType.getList({ limit: 'all' });
    }, [], []);


    const isInitialized = useMemo(() => {
        if (hideTypeColumn) {
            return true
        }
        return  productTypes.length > 0
    }, [ hideTypeColumn, productTypes.length ]);

    const getFileUrl = (id: number) => (
        `${ API_URL }/api/v1/documents/${ 'productdossier' as FileType }/${ id }`
    );

    const rows = useMemo((): TableColumns<ProductModel, ProductRelationsBluePrint>[] => {
        const tableRows = [
            {
                header: {
                    children: 'Nummer',
                    sortCol: 'number'
                },
                column: (item) => ({
                    children: item.number
                }),
                style: {
                    width: '8rem'
                },
                type: 'id'
            },
            {
                header: {
                    children: 'Product',
                    sortCol: 'name'
                },
                column: (item) => ({
                    children: item.name
                }),
                type: 'text'
            }
        ] as TableColumns<ProductModel, ProductRelationsBluePrint>[];

        if (!hideTypeColumn) {
            tableRows.push({
                header: {
                    children: 'Type'
                },
                column: (item) => ({
                    children: getProductTypeUnit(item.productType)
                }),
                type: 'text'
            });
        }

        tableRows.push({
            header: {
                children: 'Productversie',
                sortCol: 'version'
            },
            column: (item) => ({
                children: ` v` + item.version
            }),
            type: 'text'
        });

        tableRows.push(...extraColumns);
        return tableRows;
    }, [ hideTypeColumn, extraColumns ]);


    const forms: TableFormFields<ProductModel, keyof ProductModel> = useMemo(() => {
        const tableForms: TableFormFields<ProductModel, keyof ProductModel> = {};
        if (!hideEdit) {
            tableForms.edit = {
                Form: ProductForm,
                id: (record) => record.id
            };
        }

        if (!hideArchive) {
            tableForms.archive = {
                id: (record) => record.id,
                resourceName: () => 'Product',
                itemName: (record) => `${ record.name }`
            };
        }

        return tableForms;
    }, [ hideEdit, hideArchive ]);


    const tableRelations: ProductRelationsBluePrint[] = useMemo(() => {
        if (hideTypeColumn) {
            return withRelations
        }
        return [ 'productType', ...withRelations ]
    }, [ hideTypeColumn, withRelations ]);

    return (
        <>
            { isInitialized && <Table
                id={ 'products' }
                rows={ rows }
                rowActions={ {
                    linkTo: (item) => `/products/${ item.id }`
                } }
                http={ {
                    hook: httpHook,
                    searchCols: [ 'name', 'number' ],
                    sortCol: 'number',
                    sortDir: 'ASC',
                    with: tableRelations,
                    filter,
                    filterConfig: {
                        hideArchived: hideArchive
                    },
                    disableDeArchiving: hideArchive,
                    filters: productTypes.map(productType => ({
                        column: 'product_type_id',
                        displayName: productType.name ?? 'onbekend',
                        value: productType.id!
                    }))
                } }
                forms={ forms }
                callbacks={ [
                    {
                        title: 'Dossier openen',
                        onClick: (item) => {
                            window.open(getFileUrl(item.id!), '_blank');
                        },
                        disableForRow: (item) => !item.id
                    },
                    {
                        title: 'Dossier downloaden',
                        onClick: (item) => {
                            const name = `productdossier-${ item.number }-v${ item.version }`;
                            downloadFileByUrl(getFileUrl(item.id!), name, 'pdf');
                        },
                        disableForRow: (item) => !item.id
                    }
                ] }
            /> }
        </>
    );
};

export default ProductTable;
