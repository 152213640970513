import React, { FC, useMemo } from 'react';
import PackagePartForm from './PackagePartForm';
import { PackagePartModel, PackagePartRelationsBluePrint, PackagePartTypeFilter } from './PackagePartTypes';
import usePackagePart from './usePackagePart';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";


type Props = Required<ExtendTableProps>

const PackagePartTable: FC<Props> = () => {

    const httpHook = usePackagePart();

    const rows = useMemo((): TableColumns<PackagePartModel, PackagePartRelationsBluePrint>[] => [
        {
            header: {
                children: 'Volledige naam',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Afgekorte naam',
                sortCol: 'short_name'
            },
            column: (item) => ({
                children: item.short_name
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Onderdeel',
                sortCol: 'type'
            },
            column: (item) => ({
                children: item.type
            }),
            type: 'text',
        },
        {
            header: {
                children: 'Aantal gelinkte verpakkingen',
                sortCount: 'packages'
            },
            column: (item) => ({
                children: item.packages_count
            }),
            type: 'numeric',
        }
    ], []);

    return (<>
        <Table
            id={ 'package-parts' }
            rows={ rows }
            rowActions={{
                linkTo: (item) => `${item.id}`
            }}
            http={ {
                hook: httpHook,
                searchCols: ['name'],
                sortCol: 'name',
                withCount: [ 'packages' ],
                filters: PackagePartTypeFilter,
            } }
            forms={ {
                edit: {
                    Form: PackagePartForm,
                    id: (record) => record.id,
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.name }`,
                    resourceName: () => 'Verpakkingsonderdeel'
                },
            } }
        />
    </>
    );
};

export default PackagePartTable;
