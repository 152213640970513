import { PackagePartModel } from '../packagePart/PackagePartTypes';
import { PackageModel } from './PackageTypes';


/**
 * Parse resource number(s)/id(s) to display number.
 * @example singular: 10 -> 0010
 * @example multi: 1,2,3 -> 0001.0002.0003
 *
 * @param {number} num
 * @returns {string}
 */
export const parsePackageDisplayNumber = (num?: number): string => num === undefined ? '':`${num}`.padStart(4, '0');

/**
 *
 * @param parts
 * @param {boolean} short
 * @returns {string}
 */
export const getPackageNameFromParts = (parts?: any, short: boolean = true): string => {
	if ( ( parts??[] ).length > 0 && parts![0].part !== undefined ) {
		if( short == true ) {
			return parts!.map((partPivot:any) => (partPivot.part!.short_name ?? 'LEEG') ).join(' + ');
		} else {
			return parts!.map((partPivot:any) => partPivot.part!.name).join(' + ');
		}
	} else {
		return 'Bulk'; // Monkeypatch
	}
}


/**
 *
 * @param {PackageModel} packageModel
 * @param {boolean} short
 * @returns {string}
 */
export const getPackageName = (packageModel?: PackageModel, short: boolean = true): string => {
    const fallbackPackageName = packageModel?.name ?? '[onbekende verpakking]';

    if (!packageModel || !short) {
        return fallbackPackageName;
    }

    if ((packageModel.parts??[]).length > 0) {
        return packageModel.parts!.map(part => part.name).join(' + ');
    }

    if ((packageModel.packageParts??[]).length > 0 && packageModel.packageParts![0].part !== undefined) {
        return packageModel.packageParts!.map(partPivot => partPivot.part!.name).join(' + ');
    }

    return fallbackPackageName;
}


export const getPackageIdentifier = (packageModel?: PackageModel): string => {

    const fallback = '[onbekende verpakking]'
    if (!packageModel) {
        return fallback
    }

    let packageIdentifier: string = '';

    const extractIdentifier = (part: PackagePartModel): string => {

        // Monkey patch for customer specific products
        // @feature TPGAM-41
        // @see https://samfe.atlassian.net/browse/TPGAM-41
        if (`${part.type}` === 'Klant') {
            return '';
        }

        if (part.type === 'Label') {
            return part.name ?? 'label_unknown';
        }

        const rawParts = part.name?.split('(') ?? [];
        const polishedParts: string[] = [];
        let i: number = 0;
        for (const rawPart of rawParts) {
            const rawPartSplit = rawPart.split(')');
            if (rawPartSplit.length > 1) {
                const prePolishedPart = rawPartSplit[0];
                const delimiter = prePolishedPart.toLowerCase().includes('zi') ? 2 : 1;
                const newPart = rawPartSplit[0].replaceAll(' ', '').substring(0,delimiter);
                if (newPart !== '') {
                    polishedParts.push(newPart)
                }
            }

            if (i++ == 2) {
                break;
            }
        }
        return polishedParts.join('')
    }

    if ((packageModel.parts??[]).length > 0) {
        packageIdentifier = packageModel.parts!
            .map(extractIdentifier).filter(part => part !== '')
            .join('+')

    } else if ((packageModel.packageParts??[]).length > 0 && packageModel.packageParts![0].part !== undefined) {
        packageIdentifier = packageModel.packageParts!
            .map(partPivot => extractIdentifier(partPivot.part!))
            .filter(part => part !== '')
            .join('+')
    }

    // @feature TPGA-1752
    return packageIdentifier !== '' ? packageIdentifier : (packageModel.short_name ?? packageModel.name) ?? fallback;
}