import React, { FC, useEffect, useMemo, useRef } from 'react';
import { classNames } from '../../../../lib/samfe/modules/Parse/String';
import useTablePagination from '../../hooks/useTablePagination';
import useTableScrollToTop from '../../hooks/useTableScrollToTop';
import { ThComponentProps } from '../../types/TableTh';
import ThComponent from '../Th.component';


const ActiveSortButton: FC<JSX.IntrinsicElements['button']&{ sortDir: 'ASC'|'DESC' }> = ({
    children,
    className,
    onClick,
    sortDir,
    ...props
}) => {
    return <button { ...props } onClick={ onClick } className={ 'inline-flex gap-x-2' }>
        <span className={ 'underline decoration-aqua text-aqua underline-offset-2 decoration-[0.03125rem] font-semibold' }>{ children }</span>
        <span>{ sortDir == 'ASC' ?'↑' :'↓' }</span>
    </button>;
};


const InActiveSortButton: FC<JSX.IntrinsicElements['button']> = ({
    children,
    className,
    onClick,
    ...props
}) => {
    return <button { ...props } onClick={ onClick } className={ classNames('text-right underline decoration-aqua text-aqua underline-offset-2 decoration-[0.03125rem]', className) }>
        { children }
    </button>;
};


export type ThPosition = 'first'|'middle'|'last';

type Props<T extends object, R extends string> = {
    th: ThComponentProps<T, R>,
    position: ThPosition,
}

const SortThComponent = <T extends object, R extends string>({ th, position }: Props<T, R>) => {

        const [ pagination, setPagination ] = useTablePagination<T, R>();
        const scrollToTop = useTableScrollToTop();


        const sortCol = useMemo(() => th.sortCol, [ th.sortCol ]);
        const hasSortCol = useMemo(() => sortCol != undefined, [ sortCol ]);
        const sortColActive = useMemo(() => {
            if (!pagination.sortCol || !hasSortCol) {
                return false;
            }
            return sortCol == pagination.sortCol;
        }, [ sortCol, pagination.sortCol, hasSortCol ]);

        const sortCount = useMemo(() => th.sortCount, [ th.sortCount ]);
        const hasSortCount = useMemo(() => sortCount != undefined, [ sortCount ]);
        const sortCountActive = useMemo(() => {
            if (!pagination.sortCount || !hasSortCount) {
                return false;
            }
            return sortCount == pagination.sortCount;
        }, [ sortCount, pagination.sortCount, hasSortCount ]);


        const hasAction = useMemo(() => hasSortCol || hasSortCount, [ hasSortCol, hasSortCount ]);
        const isActive = useMemo(() => {
            if (!hasAction) {
                return false;
            }
            return sortColActive || sortCountActive;
        }, [ hasAction, sortColActive, sortCountActive ]);


        const handleSortClick = () => {
            let sortDir: 'ASC'|'DESC' = 'ASC';
            const isAsc = pagination.sortDir == 'ASC';

            if (hasSortCol && sortColActive && isAsc) {
                sortDir = 'DESC';
            } else if (hasSortCount && sortCountActive && isAsc) {
                sortDir = 'DESC';
            }

            setPagination({
                ...pagination,
                sortCol: sortCol,
                sortDir,
                sortCount: sortCount as string
            });
            scrollToTop()

        };


        const thRef = useRef<HTMLTableCellElement>(null);

        useEffect(() => {
            if (th.type != 'action' || !thRef.current?.parentElement) {
                return;
            }

            const tracker = document.createElement('th');
            tracker.style.width = '0px';
            tracker.style.height = '0px';
            thRef.current.parentElement.appendChild(tracker);
            const observer = new IntersectionObserver(
                ([ e ]) => {
                    if (e.isIntersecting) {
                        thRef.current?.classList.remove('border-l');
                        thRef.current?.classList.remove('bg-white');
                    } else {
                        thRef.current?.classList.add('border-l');
                        thRef.current?.classList.add('bg-white');
                    }
                },
                { threshold: [ 0.1 ], root: thRef.current.parentElement?.parentElement?.parentElement?.parentElement }
            );
            observer.observe(thRef.current.nextElementSibling!);
        }, []);


        return <ThComponent
            ref={ thRef }
            style={ th.style }
            className={ classNames(
                position == 'first' && 'pr-4 pl-4 sm:pl-10 lg:pl-12 border-t border-gray-200',
                position == 'middle' && 'px-4',
                position == 'last' && 'pl-4 pr-4 sm:pr-10 lg:pr-12',
                th.type == 'action' && 'sticky right-0 w-12',
                th.className
            ) }
        >
            <>
                {/* No sort support*/ }
                { !isActive && !hasAction && th.children }

                {/* Sort support but not active*/ }
                { !isActive && hasAction && <InActiveSortButton onClick={ handleSortClick }>
                    { th.children }
                </InActiveSortButton> }

                {/* Sort support + active */ }
                { isActive && <ActiveSortButton sortDir={ pagination.sortDir } onClick={ handleSortClick }>
                    { th.children }
                </ActiveSortButton> }
            </>
        </ThComponent>;
    }
;

export default SortThComponent;
