import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import RiskRegulationForm from './RiskRegulationForm';
import { RiskRegulationModel, RiskRegulationRelationsBluePrint } from './RiskRegulationTypes';
import useRiskRegulation from './useRiskRegulation';


type Props = ExtendTableProps

const RiskRegulationTable: FC<Props> = () => {

    const httpHook = useRiskRegulation();

    const rows = useMemo((): TableColumns<RiskRegulationModel, RiskRegulationRelationsBluePrint>[] => [
        {
            header: {
                children: 'Risicoregulatie',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name
            }),
            type: 'text'
        }
    ], []);

    return (
        <Table
            id={ 'risk-regulations' }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: [ 'name' ],
                sortCol: 'name',
                sortDir: 'ASC'
            } }
            forms={ {
                edit: {
                    Form: RiskRegulationForm,
                    id: (record) => record.id
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.name }`,
                    resourceName: () => 'Risicoregulatie'
                }
            } }
        />
    );
};

export default RiskRegulationTable;
 